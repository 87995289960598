import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: { main: '#e61464' },
    text: {
      primary: '#000000',
    },
    secondary: {
      main: '#0f5485',
    },
    error: {
      main: '#d92d20',
    },
    success: {
      main: '#039855',
    },
  },
  typography: {
    fontFamily: 'Interstate, sans-serif',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 375,
      md: 768,
      lg: 1280,
      xl: 1920,
    },
  },
});

export default theme;
