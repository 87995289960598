import { handleActions } from 'redux-actions';
import { createSimpleHandler } from 'store/utils/createSimpleHandler';
import {
  getShopsByLatLong,
  clearShops,
  setShops,
  getShop,
  setShopDetails,
  getLiveDeliverySchedule,
  clearLiveDeliverySchedule,
} from 'store/shops/actions';

import { signOut } from 'store/auth/actions';
import { Shop } from 'entities/Shop';

export interface IShopsStoreState {
  shops: {
    delivery: Shop | null;
  };
  liveDeliverySchedule: any;
}

const defaultShops = {
  delivery: null,
};

const defaultState: IShopsStoreState = {
  shops: defaultShops,
  liveDeliverySchedule: undefined,
};

const reducer = handleActions(
  {
    [setShops.type]: createSimpleHandler('shops'),
    [clearShops.type]: () => ({ ...defaultState }),
    [getShopsByLatLong.typeAPISuccess]: (
      state,
      { payload }: { payload: any }
    ) => {
      return {
        ...state,
        shops: { delivery: payload.delivery },
      };
    },
    [getShop.typeAPISuccess]: createSimpleHandler('shopDetails'),
    [setShopDetails.type]: createSimpleHandler('shopDetails'),
    [getLiveDeliverySchedule.typeAPISuccess]: createSimpleHandler(
      'liveDeliverySchedule'
    ),
    [clearLiveDeliverySchedule.type]: (state) => ({
      ...state,
      liveDeliverySchedule: undefined,
    }),
    [signOut?.type]: () => ({ ...defaultState }),
  },
  defaultState
);

export default reducer;
