import { handleActions } from 'redux-actions';
import {
  getAuthCookies,
  resetAuthCookies,
  setAuthCookies,
  setFleetUserCookie,
  UserTokens,
} from 'utilities/cookie';
import { createSimpleHandler } from 'store/utils/createSimpleHandler';
import {
  getUser,
  loginUser,
  registerUser,
  setPhoneVerified,
  setTokensFromCookie,
  setUserRegistrationData,
  signOut,
  updateConsumerAndRequest,
  updateUser,
} from 'store/auth/actions';
import { User } from 'entities/User';

export interface IAuthStoreState {
  tokens?: UserTokens;
  userRegistration?: any;
  user: User | null;
}

const defaultState: IAuthStoreState = {
  tokens: getAuthCookies(),
  userRegistration: undefined,
  user: null,
};

const reducer = handleActions<IAuthStoreState, any>(
  {
    [loginUser?.typeAPISuccess]: (state, action: { payload: UserTokens }) => {
      setAuthCookies(action);

      return { ...state, tokens: action.payload };
    },
    [setTokensFromCookie?.type]: createSimpleHandler('tokens'),
    [updateUser?.typeAPISuccess]: createSimpleHandler('user'),
    [getUser?.typeAPISuccess]: (state, action) => {
      setFleetUserCookie(!!(action.payload.fleetId && action.payload.fleet));
      return { ...state, user: { ...action.payload } };
    },
    [updateConsumerAndRequest?.typeAPISuccess]: createSimpleHandler('user'),
    [setUserRegistrationData?.type]: createSimpleHandler('userRegistration'),
    [setPhoneVerified?.type]: (state: any) => {
      return {
        ...state,
        user: {
          ...state.user,
          phoneNumberValidated: true,
        },
      };
    },
    [registerUser?.typeAPISuccess]: createSimpleHandler('user', 'consumer'),
    [registerUser?.typeAPIFail]: (state, action) => {
      return {
        ...state,
        userRegistration: {
          ...state.userRegistration,
          error: { message: action.payload.response.message },
        },
      };
    },
    [signOut?.type]: () => {
      resetAuthCookies();
      return { ...defaultState, tokens: undefined };
    },
  },
  defaultState
);

export default reducer;
