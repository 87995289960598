import { createRoot } from 'react-dom/client';
import 'styles/common/body.scss';
import 'styles/common/fonts.scss';
import 'styles/styles.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

require('promise.prototype.finally').shim(); // eslint-disable-line

window.googleApiLoadedCallback = window.googleApiLoadedCallback
  ? window.googleApiLoadedCallback
  : () => {};

(window.process as any) = window.process && {
  browser: true,
};

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
