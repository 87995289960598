import { createAPIAction } from 'store/utils/createAPIAction';
import { createAction } from 'store/utils/createAction';
import {   isNil } from 'lodash';
import { toMoneySansSign, usMoneyFormatWithCents } from 'utilities/formatting';
import { trackAnalyticsEvent } from 'store/global/actions';
import { ThunkDispatch } from 'redux-thunk';

import { DELIVERY } from '../repairRequest/constants';
import { ValidatePromocodeProps, VoucherifyValidateResponse } from './types';

const serviceName = 'promo';

export const setPromoCode = createAction(`${serviceName}/SET_PROMO_VALIDATION`);

export const clearPromoCode = createAction(`${serviceName}/CLEAR_PROMO_CODE`);

export const clearCancellationDiscount = createAction(
  `${serviceName}/CLEAR_CANCELLATION_DISCOUNT`
);

export const removePromoCode = createAPIAction(
  `${serviceName}/REMOVE_PROMO_CODE`,
  (workOrderId) => ({
    endpoint: `/repair-service/promo/consumers/work-orders/${workOrderId}`,
    method: 'DELETE',
    data: workOrderId,
  })
);

export const getPromoData = createAPIAction(
  `${serviceName}/GET_PROMO_CODE`,
  (workOrderId) => ({
    endpoint: `/repair-service/promo/consumers/work-orders/${workOrderId}`,
  })
);

// VALIDATE AND APPLY A PROMO CODE TO THE WORK ORDER
export const applyPromoCode = createAPIAction(
  `${serviceName}/APPLY_PROMO_CODE`,
  ({ workOrderId, promoCode }) => ({
    endpoint: `/repair-service/promo/consumers/work-orders/${workOrderId}/code/${promoCode}`,
    method: 'POST',
  })
);

export const getCancellationPromo = createAPIAction(
  `${serviceName}/GET_CANCELLATION_PROMO`,
  ({ workOrderId, amount }) => ({
    endpoint: `/repair-service/promo/consumers/work-orders/${workOrderId}/code`,
    method: 'POST',
    data: {
      category: 'CANCELLATIONS',
      amount,
    },
  })
);

export const validatePromoCode =
  (data: ValidatePromocodeProps) => (dispatch: ThunkDispatch<any, any, any>) =>
    new Promise((resolve) => {
      const {
        promoCode,
        totalCost,
        deliveryChannel,
        workOrderId,
        consumerEmail,
      } = data;
      let validationData = {};
      if (isNil(totalCost)) {
        return;
      }

      dispatch(setPromoCode({ code: promoCode, fetching: true }));

      // AMOUNT MUST BE SENT WITH NO DECIMALS BUT INCLUDING CENTS
      // $30.00 == 3000
      let totalCostFormatted;
      // EVEN DOLLAR AMOUNTS WON'T WORK WITH THE TOMONEYSANSSIGN FORMATTER
      if (totalCost.toString().indexOf('.') === -1) {
        totalCostFormatted = `${totalCost.toString()}00`;
      } else {
        totalCostFormatted = toMoneySansSign(totalCost)
          .toString()
          .replace('.', '');
      }
      window.Voucherify.setIdentity(consumerEmail);
      window.Voucherify.validate(
        {
          code: promoCode,
          amount: totalCostFormatted,
          metadata: {
            deliveryChannel: (deliveryChannel || DELIVERY).toUpperCase(),
          },
        },
        (response: VoucherifyValidateResponse) => {
          if (response.valid == null) {
            return;
          }

          const amountOff = response?.discount?.amount_off ?? 0;
          const percentOff = response?.discount?.percent_off ?? 0;
          const discountType = response?.discount?.type ?? 0;
          const discountAmount = response?.order?.discount_amount ?? 0;

          let validMessage = '';
          if (discountType === 'AMOUNT') {
            // ADD DECIMAL POINT
            const formattedAmountOff = (amountOff / 100).toFixed(2);
            validMessage = `${usMoneyFormatWithCents(formattedAmountOff)} Off`;
          } else if (discountType === 'PERCENT') {
            validMessage = `${percentOff}% Off`;
          }

          const valid = response?.valid ?? false;
          if (valid) {
            validationData = {
              fetching: false,
              valid,
              invalid: false,
              validMessage,
              discountAmount: discountAmount / 100,
              invalidMessage: '',
              code: promoCode,
            };

            // APPLY TO THE APPOINTMENT
            dispatch(
              applyPromoCode({
                promoCode,
                workOrderId,
              })
            ).then(() => {
              resolve(validationData);
            });
          } else {
            validationData = {
              code: '',
              fetching: false,
              valid,
              invalid: true,
              discountAmount: null,
              validMessage: '',
              invalidMessage: 'Enter a valid discount code',
            };

            resolve(validationData);
          }

          trackAnalyticsEvent('Promo Code Entered', {
            promoCode,
            valid,
          });

          dispatch(setPromoCode(validationData));
        }
      );
    });
