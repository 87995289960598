import { handleActions } from 'redux-actions';
import {
  setSnackbarMessage,
  clearSnackbarMessage,
  setSharedAnalytics,
} from 'store/global/actions';

type SnackbarMeta = {
  anchorOrigin?: {
    horizontal: 'center' | 'left' | 'right';
    vertical: 'bottom' | 'top';
  };
  mobileStylesOverride?: { [key: string]: number | string };
};

export interface GlobalStoreState {
  snackbarMessage?: string;
  snackbarMobileStyleOverride?: { [key: string]: number | string };
  snackbarMeta?: SnackbarMeta;
  variations?: { [key: string]: boolean };
  sharedAnalytics?: any;
  global?: any
}

type SnackbarMessageAction = {
  payload:
    | string
    | {
        message: string;
        meta: SnackbarMeta;
      };
};

const defaultState: GlobalStoreState = {
  snackbarMessage: undefined,
  snackbarMeta: undefined,
};

const reducer = handleActions(
  {
    [setSnackbarMessage?.type]: (
      state: GlobalStoreState,
      action: SnackbarMessageAction
    ) => {
      let message;
      let meta;

      if (typeof action.payload === 'string') {
        message = action.payload;
      } else {
        message = action.payload.message;
        meta = action.payload.meta;
      }

      return { ...state, snackbarMessage: message, snackbarMeta: meta };
    },

    [clearSnackbarMessage?.type]: (state) => ({
      ...state,
      snackbarMeta: undefined,
      snackbarMessage: undefined,
    }),
    [setSharedAnalytics?.type]: (state: any, action: any) => ({
      ...state,
      sharedAnalytics: action.payload,
    }),
  },
  defaultState
);

export default reducer;
