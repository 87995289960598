/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */

export const createAction = <P extends any, M extends any = any>(
  type: string
) => {
  const actionCreator = (payload?: P, meta?: M) => ({
    type,
    payload,
    meta,
  });

  actionCreator.type = type;
  return actionCreator;
};
