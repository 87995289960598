import { createSelector } from 'reselect';
import {   isUndefined } from 'lodash';
import { RootStoreState } from '../reducers';
import { IAuthStoreState } from './reducer';

export const selectAuth = (state: RootStoreState) => state?.auth;

export const selectTokens = createSelector(selectAuth, (auth) => auth?.tokens);

export const selectAccessToken = createSelector(
  selectTokens,
  (tokens) => tokens?.accessToken
);

export const selectRefreshToken = createSelector(
  selectTokens,
  (tokens) => tokens?.refreshToken ?? null
);

export const selectEmailVerifiedToken = createSelector(
  selectTokens,
  (tokens) => tokens?.emailVerified ?? null
);

export const isUserLoggedIn = createSelector(
  selectAccessToken,
  (accessToken) => {
    return !isUndefined(accessToken);
  }
);

export const isEmailVerified = createSelector(
  selectEmailVerifiedToken,
  (emailVerified) => emailVerified || emailVerified
);

export const selectIsFleetUser = createSelector(
  selectAuth,
  (auth) => auth?.user?.fleet ?? false
);

export const selectUserRegistrationData = createSelector(
  selectAuth,
  (auth: IAuthStoreState) => auth?.userRegistration ?? null
);

export const selectUserRegistrationFirstName = createSelector(
  selectUserRegistrationData,
  (registrationData) => registrationData?.firstName
);

export const selectUser = createSelector(selectAuth, (auth) => auth.user);

export const selectUserId = createSelector(selectUser, (user) => user?.id);

export const selectUserEmail = createSelector(
  selectUser,
  (user) => user?.email ?? ''
);

export const selectUserZipCode = createSelector(
  selectUser,
  (user) => user?.zipCode
);

export const selectConsumerFleetId = createSelector(
  selectUser,
  (user) => user?.fleetId
);

export const selectConsumerIsFleet = createSelector(
  selectUser,
  (user) => user?.fleetId && user?.fleet
);

export const selectConsumerFleet = createSelector(
  selectUser,
  (user) => user?.fleetProfile
);

export const selectConsumerPhoneValidated = createSelector(
  selectUser,
  (user) => user?.phoneNumberValidated
);

export const selectUserRegistrationError = createSelector(
  selectUserRegistrationData,
  (registrationData) => registrationData?.error
);

export const selectConsumerFleetProfile = createSelector(
  selectUser,
  (user) => user?.fleetProfile
);

export const selectConsumerAddress = createSelector(
  selectUser,
  (user) => user?.address
);
