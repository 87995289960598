import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, RouteProps, useHistory } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { RootStoreState } from 'store/reducers';
import { isUserLoggedIn as isUserLoggedInSelector } from 'store/auth/selectors';

type ProtectedRouteSelectedProps = {
  isUserLoggedIn: boolean;
};
type ProtectedRouteProps = ProtectedRouteSelectedProps & RouteProps;

const ProtectedRoute = (props: ProtectedRouteProps) => {
  const history = useHistory();
  const { isUserLoggedIn } = props;

  useEffect(() => {
    if (!isUserLoggedIn) {
      const redirectPath = window.btoa(history.location.pathname);
      history.push(`/sign-in?redirect=${redirectPath}`);
    }
  }, [isUserLoggedIn, history]);

  if (!isUserLoggedIn) {
    return null;
  }

  return <Route {...props} />;
};

const mapStateToProps = createStructuredSelector<
  RootStoreState,
  ProtectedRouteSelectedProps
>({
  isUserLoggedIn: isUserLoggedInSelector,
});

export default connect(mapStateToProps, {})(ProtectedRoute);
