export const GENERATING_QUOTE = 'GENERATING_QUOTE'; // case 1
export const PENDING_SCHEDULE = 'PENDING_SCHEDULE'; // case 1.5
export const PENDING_CONFIRM = 'PENDING_CONFIRM'; // case 2
export const CONSUMER_CONFIRMED = 'CONSUMER_CONFIRMED'; // case 2.5
export const CONFIRMED = 'CONFIRMED'; // case 3
export const COMPLETED = 'COMPLETED'; // case 4
export const COMPLETED_EXPIRED = 'COMPLETED_EXPIRED'; // case 4
export const INVOICE_UPLOADED = 'INVOICE_UPLOADED'; // case 5
export const DELIVERY_COMPLETED = 'DELIVERY_COMPLETED';
export const COMPLETED_WITH_PAID_INVOICE = 'COMPLETED_WITH_PAID_INVOICE';
export const CANCELED = 'CANCELED';
export const PENDING_RESCHEDULE = 'PENDING_RESCHEDULE';
export const PENDING_QUOTE = 'PENDING_QUOTE';
export const EXPIRED = 'EXPIRED';
export const NONE = 'NONE';

export const REFERRAL = 'REFERRAL';
export const DELIVERY = 'DELIVERY';
