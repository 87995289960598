import { handleActions } from 'redux-actions';
import Funnel from 'entities/Funnel';
import { getFunnelByParameter, setCurrentFunnel } from './actions';
import { createSimpleHandler } from '../utils/createSimpleHandler';

export interface IFunnelStoreState {
  current?: Funnel;
}

const defaultState: IFunnelStoreState = {
  current: undefined,
};

const reducer = handleActions<any>(
  {
    [getFunnelByParameter.typeAPISuccess]: createSimpleHandler('current'),
    [setCurrentFunnel.type]: createSimpleHandler('current'),
  },
  defaultState
);

export default reducer;
