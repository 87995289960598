import { SvgIcon } from '@mui/material';

const BatteryIcon = (props: any) => (
  <SvgIcon {...props} width="40" height="40" viewBox="0 0 40 40">
    <defs>
      <path id="a" d="M0 0h40.336v40.336H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <g fill="currentColor" fillRule="nonzero" mask="url(#b)">
        <path d="M34.568 12.218h-2.3V10.45c0-.973-.773-1.765-1.723-1.765h-4.043c-.95 0-1.724.792-1.724 1.765v1.768h-9.22V10.45c0-.973-.773-1.765-1.724-1.765H9.791c-.95 0-1.724.792-1.724 1.765v1.768h-2.3c-1.591 0-2.886 1.322-2.886 2.948v13.538c0 1.625 1.295 2.947 2.887 2.947h28.8c1.592 0 2.887-1.322 2.887-2.947V15.166c0-1.626-1.295-2.948-2.887-2.948zM25.93 10.45a.58.58 0 0 1 .572-.587h4.043a.58.58 0 0 1 .571.587v1.768H25.93V10.45zm-16.71 0a.58.58 0 0 1 .571-.587h4.043a.58.58 0 0 1 .572.587v1.768H9.22V10.45zm27.082 18.254c0 .976-.777 1.77-1.734 1.77h-28.8c-.956 0-1.734-.794-1.734-1.77V15.166c0-.976.778-1.77 1.734-1.77H34.568c.957 0 1.734.794 1.734 1.77v13.538z" />
        <path d="M8.067 21.051h7.491v1.178h-7.49zM29.388 21.051v-3.533h-1.153v3.533h-3.457v1.178h3.457v3.533h1.153V22.23h3.457v-1.178z" />
      </g>
    </g>
  </SvgIcon>
);

export default BatteryIcon;
