import { combineReducers } from 'redux';

import auth, { IAuthStoreState } from 'store/auth/reducer';
import autoSelection, {
  AutoSelectionStoreState,
} from 'store/autoSelection/reducer';
import consumer, { ConsumerStoreState } from 'store/consumer/reducer';
import home, { HomeStoreState } from 'store/home/reducer';
import preliminaryRepair, {
  PreliminaryRepairStoreState,
} from 'store/preliminaryRepair/reducer';
import promo, { PromoStoreState } from 'store/promo/reducer';
import repairRequest, {
  RepairRequestStoreState,
} from 'store/repairRequest/reducer';
import repairs, { RepairsStoreState } from 'store/repairs/reducer';
import shops, { IShopsStoreState } from 'store/shops/reducer';
import inspections, { InspectionsStoreState } from 'store/inspections/reducer';
import global, { GlobalStoreState } from 'store/global/reducer';
import funnel, { IFunnelStoreState } from 'store/funnel/reducer';
import fleet from 'store/fleet/reducer';

const rootReducer = combineReducers({
  auth,
  autoSelection,
  consumer,
  home,
  preliminaryRepair,
  promo,
  repairRequest,
  repairs,
  shops,
  inspections,
  global,
  funnel,
  fleet,
});

export type AnyStoreState =
  | IAuthStoreState
  | AutoSelectionStoreState
  | ConsumerStoreState
  | HomeStoreState
  | PreliminaryRepairStoreState
  | PromoStoreState
  | RepairRequestStoreState
  | RepairsStoreState
  | IShopsStoreState
  | InspectionsStoreState
  | GlobalStoreState
  | IFunnelStoreState;

export type RootStoreState = ReturnType<typeof rootReducer>;

export default rootReducer;
