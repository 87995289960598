import { createAPIAction } from '../utils/createAPIAction';
import { createAction } from '../utils/createAction';

const serviceName = 'consumer';

export const getFunnelByParameter = createAPIAction(
  `${serviceName}/GET_FUNNEL_BY_PARAMETER`,
  (urlParameter: string) => ({
    endpoint: `/consumer-service/funnel/${urlParameter}`,
    method: 'GET',
  })
);

export const setCurrentFunnel = createAction(
  `${serviceName}/SET_CURRENT_FUNNEL`
);
