import * as gql from 'gql-query-builder';
import {
  FindLatestByConsumerIdVariables,
  GetAllByConsumerIdVariables,
} from './types';

const inspectionFields = [
  'id',
  'repairRequestId',
  'repairRefNum',
  'consumerId',
  'carId',
  'issuesCount',
  'completedAt',
  'performedById',
  'images',
  { checklistAnswers: ['id', 'inspectionId', 'checklistQuestionId', 'answer'] },
  {
    inspectionType: [
      'id',
      'name',
      'type',
      {
        checklistAnswerLabels: ['needsWorkLabel', 'okLabel'],
      },
      {
        inspectionTypeCategories: [
          {
            category: [
              'id',
              'name',
              {
                checklistQuestions: ['id', 'inspectionCategoryId', 'question'],
              },
            ],
          },
          {
            components: ['id', 'name'],
          },
        ],
      },
    ],
  },
  {
    inspectionCategoryResults: [
      'id',
      {
        inspectionCategory: ['id', 'name'],
      },
      {
        payload: [
          'status',
          {
            tireTreadMeasurements: [
              'frontLeft',
              'frontRight',
              'backLeft',
              'backRight',
            ],
          },
          {
            tireTreadMeasurementsMiddle: [
              'frontLeft',
              'frontRight',
              'backLeft',
              'backRight',
            ],
          },
          {
            tireTreadMeasurementsOuter: [
              'frontLeft',
              'frontRight',
              'backLeft',
              'backRight',
            ],
          },
          {
            tirePressureMeasurements: [
              'frontLeft',
              'frontRight',
              'backLeft',
              'backRight',
            ],
          },
          {
            brakePadMeasurements: [
              'frontLeft',
              'frontRight',
              'backLeft',
              'backRight',
            ],
          },
          {
            batteryReadings: ['coldCrankAmps', 'ratedColdCrankAmps', 'voltage'],
          },
        ],
      },
    ],
  },
  {
    inspectionResults: [
      'id',
      'status',
      'images',
      'repairId',
      'repairName',
      { payload: ['additionalInformation', 'customServiceRequest'] },
      { inspectionComponent: ['id', 'name'] },
      {
        inspectionCategory: [
          'id',
          'name',
          // { inspectionComponents: ['id', 'name'] },
          // got this error: Cannot return null for non-nullable field InspectionCategory.inspectionComponents.,
          // locations: [ { line: 1, column: 576 } ]
        ],
      },
      { inspectionRsRepair: ['name'] },
      { inspectionSymptoms: ['name'] },
    ],
  },
];

export const findLatestOneByConsumerId = ({
  consumerId,
  withinHours,
}: FindLatestByConsumerIdVariables) =>
  gql.query({
    operation: 'findLatestOneByConsumerId',
    variables: {
      consumerId: { value: consumerId, required: true },
      withinHours,
    },
    fields: ['id', 'repairRefNum', 'createdAt'],
  });

export const findLatestByConsumerId = ({
  consumerId,
  withinHours,
}: FindLatestByConsumerIdVariables) =>
  gql.query({
    operation: 'findLatestByConsumerId',
    variables: {
      consumerId: { value: consumerId, required: true },
      withinHours,
    },
    fields: ['id', 'repairRefNum', 'createdAt'],
  });

export const getAllByConsumerId = ({
  consumerId,
  take = 3,
}: GetAllByConsumerIdVariables) =>
  gql.query({
    operation: 'getAllByConsumerId',
    variables: {
      consumerId: { value: consumerId, required: true },
      take: { value: take, required: true },
      orderBy: { value: 'created_at' },
    },
    fields: [
      'id',
      'issuesCount',
      'consumerId',
      'repairRefNum',
      'createdAt',
      { inspectionType: ['id'] },
      { inspectionResults: ['id', 'status'] },
    ],
  });

export const findInspectionByRepairRequestId = (data: {
  repairRequestId: string;
}) =>
  gql.query({
    operation: 'findByRepairRequestId',
    variables: {
      repairRequestId: { value: data.repairRequestId, required: true },
    },
    fields: inspectionFields,
  });

export const findInspectionsByConsumerId = (data: { consumerId: number }) =>
  gql.query({
    operation: 'getAllByConsumerId',
    variables: {
      consumerId: { value: data.consumerId, required: true },
      orderBy: { value: 'completed_at' },
      orderDirection: { value: 'desc' },
    },
    fields: [
      'id',
      'repairRefNum',
      'completedAt',
      'carId',
      'issuesCount',
      { inspectionResults: ['id', 'status'] },
      { inspectionType: ['name'] },
    ],
  });

export const findInspection = (data: { inspectionId: string }) =>
  gql.query({
    operation: 'inspection',
    variables: {
      id: { value: data.inspectionId, required: true },
    },
    fields: inspectionFields,
  });

export const findLatestByRepairRefNum = (data: { repairRefNum: string }) =>
  gql.query({
    operation: 'findLatestByRepairRefNum',
    variables: {
      repairRefNum: { value: data.repairRefNum, required: true },
    },
    fields: inspectionFields,
  });
