import { handleActions } from 'redux-actions';
import { createSimpleHandler } from 'store/utils/createSimpleHandler';
import { getMakesStatic } from 'store/home/actions';

export interface HomeStoreState {
  makesStatic: any[];
  home: any;
}

const defaultState: HomeStoreState = {
  makesStatic: [],
  home: undefined,
};

const reducer = handleActions(
  {
    [getMakesStatic.typeAPISuccess]: createSimpleHandler('makesStatic'),
  },
  defaultState
);

export default reducer;
