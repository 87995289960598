const initialPagination = {
  totalElements: 1,
  last: false,
  first: true,
  size: 20,
  nextPage: 0,
};

const createAPIPaginationHandler =
  (basePath: string, pathFromPayload?: string, loadAll?: boolean) =>
  (state: any, { payload }: any) => {
    if (!payload) return state;
    const newData = pathFromPayload
      ? payload[pathFromPayload] ?? []
      : payload || [];

    return {
      ...state,
      [basePath]: {
        data: newData,
        requesting: false,
        errors: [],
        pagination: loadAll
          ? initialPagination
          : {
              totalElements: payload.totalElements,
              first: payload.first,
              last: payload.last,
              size: payload.size,
              page: payload.number,
              nextPage: payload.number + 1,
            },
      },
    };
  };

export { createAPIPaginationHandler, initialPagination };
