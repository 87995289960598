import { handleActions } from 'redux-actions';
import { signOut } from 'store/auth/actions';
import { Inspection } from 'entities/Inspection';
import { InspectionResult } from 'entities/InspectionResult';
import { ZendeskTicket } from 'entities/ZendeskTicket';
import { MpiReport } from 'entities/MpiReport';
import {
  getLastUserInspection,
  getInspectionByRepairRequestId,
  getInspection,
  getHealthInspectionHistory,
  getLatestInspectionByRepairRefNum,
  updateInspectionRepairs,
  getLastUserInspections,
  getQuoteNeededZendeskTicket,
  getReport,
} from './actions';
import { createSimpleHandler } from '../utils/createSimpleHandler';
import { CUSTOM_REQUEST_REPAIR_ID } from '../../pages/HealthReport/constants';

export interface InspectionsStoreState {
  lastInspection?: Inspection;
  inspection?: Partial<Inspection>;
  inspectionHistory?: Partial<Inspection>[];
  latest?: Inspection[];
  quoteNeededZendeskTickets?: ZendeskTicket[];
  report?: MpiReport;
}

const defaultState: InspectionsStoreState = {
  lastInspection: undefined,
  inspection: undefined,
  inspectionHistory: undefined,
  latest: undefined,
  quoteNeededZendeskTickets: undefined,
  report: undefined,
};

const reducer = handleActions<InspectionsStoreState, any>(
  {
    [getLastUserInspection.typeAPISuccess]: createSimpleHandler(
      'lastInspection',
      'data.findLatestOneByConsumerId'
    ),
    [getInspectionByRepairRequestId.typeAPISuccess]: createSimpleHandler(
      'inspection',
      'data.getInspectionByRepairRequestId'
    ),
    [getLatestInspectionByRepairRefNum.typeAPISuccess]: createSimpleHandler(
      'inspection',
      'data.findLatestByRepairRefNum'
    ),
    [getInspection.typeAPISuccess]: createSimpleHandler(
      'inspection',
      'data.inspection'
    ),
    [getHealthInspectionHistory.typeAPISuccess]: createSimpleHandler(
      'inspectionHistory',
      'data.getAllByConsumerId'
    ),
    [updateInspectionRepairs.type]: (
      state,
      action: { payload: { [repairName: string]: any } }
    ) => {
      const { repairs } = action.payload;

      const result = {
        ...state,
        inspection: {
          ...state.inspection,
          inspectionResults: (state.inspection?.inspectionResults || []).map(
            (service: InspectionResult) => {
              const isCustom = service.repairId === CUSTOM_REQUEST_REPAIR_ID;

              const repair =
                (isCustom
                  ? action.payload[
                      (service.payload?.customServiceRequest as string).trim()
                    ]
                  : action.payload[service.repairId]) ?? {};
              const field = isCustom
                ? service.payload?.customServiceRequest?.trim()
                : service.repairName;
              const additionalFields =
                field && repairs
                  ? {
                      totalCost: repairs[field]?.totalCost,
                      nodeId: repairs[field]?.nodeId,
                    }
                  : {};

              return {
                ...service,
                ...repair,
                ...additionalFields,
                status: repair?.status || service.status,
              };
            }
          ),
        },
      };

      return result;
    },
    [getLastUserInspections.typeAPISuccess]: createSimpleHandler(
      'latest',
      'data.findLatestByConsumerId'
    ),
    [getQuoteNeededZendeskTicket.typeAPISuccess]: createSimpleHandler(
      'quoteNeededZendeskTickets'
    ),
    [getReport.typeAPISuccess]: createSimpleHandler('report'),
    [signOut?.type]: () => {
      return { ...defaultState };
    },
  },
  defaultState
);

export default reducer;
