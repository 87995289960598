import { SvgIcon } from '@mui/material';

const SparkIcon = (props: any) => (
  <SvgIcon {...props} width="40" height="40" viewBox="0 0 40 40">
    <defs>
      <path id="a" d="M0 0h40.336v40.336H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <g
        fill="currentColor"
        fillRule="nonzero"
        stroke="currentColor"
        mask="url(#b)"
      >
        <path
          strokeWidth=".5"
          d="M32.42 6.56l-2.555 2.53-.236-.234c-.757-.75-2.034-.75-2.791 0l-9.366 9.282-.52-.516c-.52-.516-1.467-.516-1.987 0l-1.467 1.453c-.567.563-.567 1.453 0 1.969l.52.515-4.966 4.922v.047l-3.784 3.75c-.946.938-.946 2.391 0 3.329l1.324 1.312a.46.46 0 0 0 .662 0 .45.45 0 0 0 0-.656L5.93 32.95a1.406 1.406 0 0 1 0-2.015l2.46-2.438c.047.516.284.985.662 1.36l.142.14-1.42 1.406a.45.45 0 0 0 0 .657L9.43 33.7a.46.46 0 0 0 .663 0l1.419-1.406.142.14c.946.938 2.46.938 3.405 0l4.967-4.921.52.515c.568.563 1.467.563 1.987 0L24 26.575c.567-.562.567-1.453 0-1.969l-.52-.515 9.365-9.282a1.968 1.968 0 0 0 0-2.765l-.19-.188 2.508-2.484a1.968 1.968 0 0 0 0-2.766c-.71-.797-1.987-.797-2.744-.047zM9.808 32.715l-.994-.984 1.088-1.079.994.985-1.088 1.078zm4.635-.89a1.494 1.494 0 0 1-2.08 0L9.713 29.2c-.52-.515-.615-1.406 0-2.062l.19-.188 1.655 1.64a.46.46 0 0 0 .662 0 .45.45 0 0 0 0-.655l-1.655-1.641.756-.75 1.656 1.64a.46.46 0 0 0 .662 0 .45.45 0 0 0 0-.656l-1.655-1.64.757-.75 1.655 1.64a.46.46 0 0 0 .662 0 .45.45 0 0 0 0-.656l-1.655-1.64 1.23-1.22 4.73 4.688-4.92 4.875zm8.94-5.813l-1.466 1.453a.46.46 0 0 1-.662 0c-9.839-9.75-6.15-6.094-7.095-7.032a.45.45 0 0 1 0-.656l1.466-1.453a.46.46 0 0 1 .662 0l7.096 7.031c.189.141.189.47 0 .657zM32.23 14.2l-9.366 9.281-4.73-4.687L27.5 9.512c.378-.375 1.088-.375 1.466 0l3.264 3.235c.426.375.426 1.031 0 1.453zm2.318-5.531l-2.507 2.484L30.575 9.7l2.507-2.485c.378-.375 1.04-.375 1.466 0 .378.422.378 1.079 0 1.454z"
        />
        <path
          strokeWidth=".579"
          d="M19.411 23.481c-.189.188-.189.516 0 .657a.46.46 0 0 0 .662 0c.19-.188.19-.516 0-.657-.189-.187-.52-.187-.662 0zM21.303 25.403a.45.45 0 0 0 0 .657.46.46 0 0 0 .663 0 .45.45 0 0 0 0-.657c-.142-.187-.473-.187-.663 0z"
        />
      </g>
    </g>
  </SvgIcon>
);

export default SparkIcon;
