import { set } from 'lodash';
import { AnyStoreState } from '../reducers';

/**
 *  Creates a function to update a single field in a reducer
 */
export const createClearHandler = <T>(
  fieldName: string,
  defaultValue: any = null
) => (state: AnyStoreState): T => set<T>({ ...state }, fieldName, defaultValue);
