import { createSelector } from 'reselect';
import { isNil, orderBy, sortBy } from 'lodash';
import moment from 'moment';
import { ConsumerStoreState } from 'store/consumer/reducer';
import { NONE } from 'store/repairRequest/constants';
import { Diagnose } from 'entities/Diagnose';
import { CarsGroup } from 'entities/CarsGroup';
import { Car } from 'entities/Car';
import {
  KnowRepairWithPrice,
  PreliminaryKnowRepair,
} from 'entities/KnowRepair';
import { ServiceListItem } from 'entities/AppointmentData';
import { selectRepairsList } from 'store/repairs/selectors';
import { selectUserZipCode } from 'store/auth/selectors';
import { Credit } from 'entities/Credit';
import { RootStoreState } from '../reducers';

export const getConsumer = (state: RootStoreState) => state?.consumer ?? {};

export const selectUser = createSelector(
  getConsumer,
  (consumer) => consumer?.user
);

export const selectUserData = createSelector(selectUser, (user) => user?.data);

export const selectUserEmail = createSelector(
  selectUserData,
  (userData) => userData?.email ?? ''
);

export const selectAppointments = createSelector(
  getConsumer,
  (consumer) => consumer?.appointments ?? {}
);

export const selectAppointmentsHistory = createSelector(
  getConsumer,
  (consumer) => consumer?.appointmentsHistory ?? {}
);

export const getCars = (state: RootStoreState) => (state as any)?.cars;

export const selectCar = createSelector(
  getConsumer,
  (consumer) => consumer.car
);

export const selectConsumerCars = createSelector(
  getConsumer,
  (consumer) => consumer?.cars ?? null
);

export const selectCarsFiltered = createSelector(
  getConsumer,
  (consumer) => consumer.carsFiltered?.data?.content
);

export const selectCarsFilteredPagination = createSelector(
  getConsumer,
  (consumer) => consumer.carsFiltered?.data ?? {}
);

export const selectCarsAggregatedInfo = createSelector(
  getConsumer,
  (consumer) => {
    const aggData = consumer.carsAggregatedInfo?.data ?? {};
    const aggNew: any = {};

    Object.keys(aggData).forEach((key) => {
      const keyMap = new Map();

      aggData[key].forEach((kv: any) => {
        let name = kv.label;
        if (key === 'mileages') {
          name = `${kv.mileageMin} ${
            kv.mileageMax ? `to ${kv.mileageMax}` : 'and up'
          }`;
        }

        keyMap.set(name, { count: kv.count, name });
      });
      aggNew[key] =
        key === 'mileages' ? new Map(Array.from(keyMap).reverse()) : keyMap;
    });

    return aggNew;
  }
);

export const selectConsumerCarsData = createSelector(
  getConsumer,
  (consumer) => {
    const cars = consumer.cars?.data?.carDetails;

    return cars ? sortBy(cars, 'id') : cars;
  }
);

export const selectConsumerCarsWithSubscriptions = createSelector(
  selectConsumerCarsData,
  (allCars) => allCars?.filter((car: any) => car.subscriptions)
);

export const selectHasCarsNotSubscribed = createSelector(
  selectConsumerCarsData,
  selectConsumerCarsWithSubscriptions,
  (allCars, carsWithSubs) =>
    (allCars ?? []).length - (carsWithSubs ?? []).length > 0
);

export const selectAddCarErrors = createSelector(
  selectConsumerCars,
  getCars,
  (cars, globalCars) => {
    if ((cars?.errors ?? []).length) {
      return cars?.errors ?? [];
    }
    if ((globalCars?.carByPlate?.errors ?? []).length) {
      return globalCars?.carByPlate?.errors ?? [];
    }
    return [];
  }
);

export const selectServicesList = (
  customServiceRequests: { message: string }[] = [],
  diagnosesRepairs: Diagnose[] = [],
  knownRepairs: KnowRepairWithPrice[] | PreliminaryKnowRepair[] = []
): ServiceListItem[] => {
  const repairsDetails: ServiceListItem[] = [];

  // CUSTOM SERVICE REQUESTS
  if (customServiceRequests.length > 0) {
    customServiceRequests.forEach((request) => {
      repairsDetails.push({
        name: `Custom Service Request: ${request.message}`,
      });
    });
  }

  // DIAGNOSES
  if (diagnosesRepairs.length > 0) {
    diagnosesRepairs.forEach((diagnosis: any) => {
      const firstSymptom =
        diagnosis?.symptoms[0]?.name || diagnosis?.symptoms[0]?.symptom;
      repairsDetails.push({ name: `Inspection: ${firstSymptom}` });
    });
  }

  // KNOWN REPAIRS
  if (knownRepairs.length > 0) {
    knownRepairs.forEach(
      (repair: PreliminaryKnowRepair | KnowRepairWithPrice) => {
        const r = repair as KnowRepairWithPrice;
        repairsDetails.push({
          name: r.name,
          dueDate: r.price ? r.price[0].dueDate : null,
        });
      }
    );
  }

  return repairsDetails;
};

const formatAppointments = (appointments: any) => {
  let data = appointments?.data ?? [];

  if (!data) return [];

  data = data
    .filter((appointment: any) => appointment.trackerState !== NONE)
    .map((appointment: any) => {
      const entity = !isNil(appointment.invoice)
        ? appointment.invoice.diagnosisServices
        : appointment.diagnosis.diagnosisServices;
      const list = selectServicesList(
        entity?.customRequests,
        entity?.diagnoses,
        entity?.knownRepairs
      );

      const preferredTimes = (
        appointment?.repairRequest?.consumerPreferredTimes ?? []
      ).map((date: any) =>
        moment(
          `${date.appointmentDate} ${date.appointmentTime}`,
          'YYYY-MM-DD HH:mm:ss'
        )
      );

      return {
        ...appointment,
        servicesList: list,
        consumerPreferredTimes: preferredTimes,
      };
    });

  return data;
};

export const selectFormattedAppointments = createSelector(
  selectAppointments,
  formatAppointments
);

export const selectFormattedAppointmentsHistory = createSelector(
  selectAppointmentsHistory,
  formatAppointments
);

export const selectConsumerAddresses = createSelector(
  getConsumer,
  (consumer) => consumer.addresses
);

export const selectDeliveryAddress = createSelector(
  getConsumer,
  (consumer) => consumer?.deliveryAddress
);

export const selectConsumerSelectedAddress = createSelector(
  getConsumer,
  (consumer) => consumer?.consumerSelectedAddress ?? false
);

export const selectGroups = createSelector(
  getConsumer,
  (consumer): CarsGroup[] | undefined => {
    const groups = consumer.groups.data;

    return groups ? orderBy(groups, ['order'], ['asc']) : undefined;
  }
);

export const selectGroupsJoinCars = createSelector(
  selectGroups,
  selectConsumerCarsData,
  (groups: CarsGroup[] | undefined, cars: Car[]): CarsGroup[] => {
    return (groups ?? []).map((g) => ({
      ...g,
      cars: cars?.filter((c) => c.group && c.group.id === g.id),
    }));
  }
);

export const selectGroupsJoinCarsWithUngroupedCars = createSelector(
  selectGroupsJoinCars,
  selectConsumerCarsData,
  (groupsJoinCars: CarsGroup[], cars: Car[]): CarsGroup[] => {
    if (groupsJoinCars.length > 0) {
      const otherCars = cars?.filter(
        (car) =>
          !car.group ||
          !groupsJoinCars.find(
            (group) => group.id === (car.group && car.group.id)
          )
      );

      if (otherCars?.length) {
        return [
          ...groupsJoinCars,
          {
            id: 'ungrouped',
            order: -1,
            label: 'Ungrouped Cars',
            cars: otherCars,
          },
        ];
      }
    }

    return groupsJoinCars;
  }
);

export const selectPaymentMethods = createSelector(
  getConsumer,
  (consumer) => consumer.paymentMethods ?? []
);

export const selectCreditCards = createSelector(
  selectPaymentMethods,
  (methods) => methods.filter((m: any) => m.methodType === 'CREDIT_CARD')
);

export const selectVwoExperimentsViewed = createSelector(
  getConsumer,
  (consumer) => consumer.vwoExperimentsViewed
);

export const selectMostRecentZip = createSelector(
  getConsumer,
  selectUserZipCode,
  (consumer: ConsumerStoreState, consumerZip?: string) => {
    const { addresses } = consumer || {};

    if (addresses?.length) {
      return addresses[0].zip;
    }
    return consumerZip;
  }
);

export const selectMostRecentTimezone = createSelector(
  getConsumer,
  (consumer: ConsumerStoreState): string | undefined => {
    const { addresses } = consumer || {};

    if (addresses?.length) {
      return addresses[0].timezone;
    }
  }
);

export const selectCredits = createSelector(
  getConsumer,
  selectRepairsList,
  selectConsumerCarsData,
  (consumer, repairsList, cars) => {
    if (!repairsList) return [];
    if (!cars) return [];

    const credits: Credit[] = [];
    const sortedCredits = sortBy(consumer.credits, 'expirationDate');
    sortedCredits?.forEach((credit: Credit) => {
      if (credit.status === 'APPLIED') {
        const newCredit = { ...credit };
        const foundRepair = repairsList?.find(
          (r) => r.repairId[0] === credit.productId
        );
        if (foundRepair) {
          newCredit.productName = foundRepair.repairName;
        }

        credits.push(newCredit);
        return;
      }
      const addedCredit = credits.find(
        (c: Credit) =>
          c.consumerCarId === credit.consumerCarId &&
          credit.productId === c.productId &&
          c.status === 'ACTIVE'
      );

      if (addedCredit) {
        if (!addedCredit.productCount) {
          addedCredit.productCount = 0;
        }
        addedCredit.productCount += 1;
      } else {
        const newCredit = { ...credit };
        const foundRepair = repairsList?.find(
          (r) => r.repairId[0] === credit.productId
        );
        if (foundRepair) {
          newCredit.productName = foundRepair.repairName;
        }
        newCredit.consumerCar = cars.find(
          (car: any) => car.id === credit.consumerCarId
        );
        newCredit.productCount = 1;
        credits.push(newCredit);
      }
    });

    return credits;
  }
);
