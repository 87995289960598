import { SvgIcon } from '@mui/material';

const FactoryMaintenanceIcon = (props: any) => (
  <SvgIcon {...props} width="56" height="56" viewBox="0 0 56 56">
    <defs>
      <path id="a" d="M0 0h56v56H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <g fill="currentColor" fillRule="nonzero" mask="url(#b)">
        <path d="M47.65 35.133a8.002 8.002 0 0 0-8.148-1.968l-6.093-6.117 7.217-7.245 4.59-2.576a.732.732 0 0 0 .296-.312l2.063-4.141a.734.734 0 0 0-.137-.845L44.86 9.34a.727.727 0 0 0-.842-.137l-4.125 2.07a.731.731 0 0 0-.31.298l-2.567 4.608-7.217 7.245-5.795-5.817a8.078 8.078 0 0 0-1.96-8.18 8.002 8.002 0 0 0-8.602-1.803.731.731 0 0 0-.25 1.199l4.639 4.657-3.094 3.106-4.639-4.657a.729.729 0 0 0-1.194.25 8.08 8.08 0 0 0 1.796 8.637 8.002 8.002 0 0 0 8.148 1.967l5.795 5.818-.836.84-2.063-2.071a.729.729 0 0 0-1.207.286l-.947 2.85-7.47 4.529a.732.732 0 0 0-.175.148l-3.843 4.471c-1.556 1.81-1.451 4.658.233 6.349l2.583 2.594c.884.887 2.085 1.335 3.287 1.335 1.091 0 2.184-.37 3.042-1.116l4.541-3.952a.729.729 0 0 0 .147-.176l4.42-7.391 2.839-.95a.731.731 0 0 0 .285-1.212l-2.063-2.07.836-.84 6.094 6.117a8.078 8.078 0 0 0 1.96 8.18 8.007 8.007 0 0 0 8.602 1.803.731.731 0 0 0 .25-1.2l-4.64-4.656 3.095-3.106 4.638 4.657a.728.728 0 0 0 1.195-.25 8.078 8.078 0 0 0-1.796-8.637zM19.553 21.42a.727.727 0 0 0-.782-.164 6.548 6.548 0 0 1-7.04-1.476 6.61 6.61 0 0 1-1.797-5.946l4.287 4.304a.727.727 0 0 0 1.032 0l4.125-4.141a.734.734 0 0 0 0-1.036l-4.287-4.304a6.55 6.55 0 0 1 5.922 1.805 6.61 6.61 0 0 1 1.47 7.067.734.734 0 0 0 .164.785l6.121 6.145-3.094 3.106-6.121-6.145zm8.06 13.911l-1.974.661a.73.73 0 0 0-.395.318l-4.5 7.523-4.452 3.875c-1.239 1.079-3.187 1.012-4.342-.148l-2.584-2.594c-1.156-1.16-1.228-3.114-.16-4.356l3.767-4.383 7.601-4.608c.149-.09.26-.23.315-.395l.658-1.982 1.744 1.75 2.578 2.589 1.744 1.75zm-1.228-3.303l-1.547-1.553L38.12 17.143a.73.73 0 0 0 .12-.16l2.507-4.5 3.454-1.734 1.835 1.842-1.727 3.468-4.482 2.516a.728.728 0 0 0-.16.12L26.386 32.03zm22.03 10.085L44.13 37.81a.727.727 0 0 0-1.032 0l-4.125 4.141a.734.734 0 0 0 0 1.036l4.287 4.304a6.552 6.552 0 0 1-5.922-1.805 6.61 6.61 0 0 1-1.47-7.067.734.734 0 0 0-.164-.785l-6.42-6.445 3.094-3.106 6.42 6.445c.205.206.512.27.782.164a6.548 6.548 0 0 1 7.04 1.476 6.609 6.609 0 0 1 1.797 5.946z" />
      </g>
    </g>
  </SvgIcon>
);

export default FactoryMaintenanceIcon;
