import OilChangeIcon from 'components/common/Icons/HowCanWeHelp/OilChangeIcon';
import PadsIcon from 'components/common/Icons/HowCanWeHelp/PadsIcon';
import BatteryIcon from 'components/common/Icons/HowCanWeHelp/BatteryIcon';
import SparkIcon from 'components/common/Icons/HowCanWeHelp/SparkIcon';
import FactoryMaintenanceIcon from 'components/common/Icons/HowCanWeHelp/FactoryMaintenanceIcon';

export const favoriteCommonRepairsData = [
  {
    id: 12,
    icon: OilChangeIcon,
  },
  {
    id: 23,
    icon: PadsIcon,
  },
  {
    id: 24,
    icon: PadsIcon,
  },
  {
    id: 6,
    icon: BatteryIcon,
  },
  {
    id: 165,
    icon: FactoryMaintenanceIcon,
  },
  {
    id: 5,
    icon: SparkIcon,
  },
];
