import { SvgIcon } from '@mui/material';

const PadsIcon = (props: any) => (
  <SvgIcon {...props} width="40" height="40" viewBox="0 0 40 40">
    <defs>
      <path id="a" d="M0 0h40.336v40.336H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <g fill="currentColor" fillRule="nonzero" mask="url(#b)">
        <path d="M21.629 5.3a15.41 15.41 0 0 0-3.514.409l-.108-.39a1.801 1.801 0 0 0-.642-.946h.135-.136a1.776 1.776 0 0 0-.255-.17 1.767 1.767 0 0 0-1.408-.101C9.013 6.478 4.379 12.605 3.894 19.712a1.795 1.795 0 0 0 1.784 1.911h.396c.351 8.321 7.192 14.982 15.555 14.982 8.586 0 15.572-7.022 15.572-15.653S30.215 5.299 21.629 5.299zM5.346 20.134a.439.439 0 0 1-.119-.33c.448-6.573 4.734-12.24 10.92-14.437a.42.42 0 0 1 .344.026c.113.06.195.16.229.284L17 6.695l.001.002v.001l1.544 5.586c.06.22-.06.45-.288.546a8.801 8.801 0 0 0-5.322 7.079.438.438 0 0 1-.438.371h-6.82a.45.45 0 0 1-.331-.145zm16.283 15.127c-7.626 0-13.869-6.059-14.22-13.639h5.088c.902 0 1.661-.669 1.766-1.556a7.457 7.457 0 0 1 4.51-5.998 1.815 1.815 0 0 0 1.06-2.144l-1.36-4.922c1.023-.235 2.08-.36 3.156-.36 7.85 0 14.236 6.419 14.236 14.309s-6.387 14.31-14.236 14.31z" />
        <path
          stroke="currentColor"
          strokeWidth=".5"
          d="M21.629 14.25c-3.677 0-6.668 3.006-6.668 6.702s2.99 6.703 6.668 6.703c3.677 0 6.668-3.007 6.668-6.703s-2.991-6.703-6.668-6.703zm0 12.51c-3.186 0-5.777-2.606-5.777-5.808s2.591-5.807 5.777-5.807c3.185 0 5.777 2.605 5.777 5.807 0 3.202-2.592 5.807-5.777 5.807z"
        />
        <path d="M19.27 23.587a.74.74 0 0 0-.738.742c0 .41.33.743.738.743a.74.74 0 0 0 .74-.743.74.74 0 0 0-.74-.742zM23.988 23.587a.74.74 0 0 0-.739.742c0 .41.331.743.74.743a.74.74 0 0 0 .738-.743.74.74 0 0 0-.739-.742zM24.723 19.804c0 .41.331.742.74.742a.74.74 0 0 0 .738-.742.74.74 0 0 0-.739-.743.74.74 0 0 0-.739.743zM17.796 19.06a.74.74 0 0 0-.74.744c0 .41.331.742.74.742a.74.74 0 0 0 .738-.742.74.74 0 0 0-.738-.743zM21.629 17.743a.74.74 0 0 0 .739-.743.74.74 0 0 0-.74-.742.74.74 0 0 0-.738.742c0 .41.33.743.739.743z" />
        <path
          stroke="currentColor"
          strokeWidth=".5"
          d="M24.086 20.952a2.466 2.466 0 0 0-2.457-2.47 2.466 2.466 0 0 0-2.457 2.47 2.466 2.466 0 0 0 2.457 2.47 2.466 2.466 0 0 0 2.457-2.47zm-2.457 1.574a1.572 1.572 0 0 1-1.566-1.574c0-.868.702-1.574 1.566-1.574.863 0 1.566.706 1.566 1.574 0 .868-.703 1.574-1.566 1.574z"
        />
      </g>
    </g>
  </SvgIcon>
);

export default PadsIcon;
