import { every, orderBy, reject } from 'lodash';
import { createSelector } from 'reselect';
import { favoriteCommonRepairsData } from 'store/repairs/constants';
import { Repair } from 'entities/Repair';
import { RootStoreState } from 'store/reducers';
import moment from 'moment';
import { arrayInsert } from 'utilities/arrayInsert';
import { Car } from 'entities/Car';
import { RepairSimple } from 'entities/RepairSimple';
import { SearchRepair } from 'entities/SearchRepair';
import { getCarServicesNum } from 'pages/HealthReportsList/RecommendedServices/helpers';
import { RecommendedRepair } from './reducer';

const selectRepairs = (state: RootStoreState) => state?.repairs;

type CarWithRecommendedRepairs = {
  car: Car;
  repairs: RecommendedRepair[];
  mpiId: string | null;
};

export const selectRepairsList = createSelector(
  selectRepairs,
  (repairs) => repairs.allRepairs
);

export const selectTopTenRepairs = createSelector(
  selectRepairsList,
  (repairs) => {
    let ranked = reject(repairs, ['rank', null]);
    ranked = orderBy(ranked, ['rank'], ['asc']);
    return ranked;
  }
);

const filterRepairs = (repairs: any[], repairIds: number[]) => {
  const popularRepairs: Repair[] = [];

  repairIds.forEach((repairId: any) => {
    const foundRepair = repairs.find(
      (topRepair: any) => topRepair.repairId[0] === repairId
    );
    if (foundRepair) {
      popularRepairs.push(foundRepair);
    }
  });
  return popularRepairs;
};

export const selectPopularRepairs = createSelector(
  selectTopTenRepairs,
  (topTenRepairs) => {
    // WE ONLY REQUIRE THESE REPAIRS IN THIS ORDER
    const popularRepairIds = [12, 23, 24, 5, 29, 30];
    return filterRepairs(topTenRepairs, popularRepairIds);
  }
);

export const selectHomepageRepairs = createSelector(
  selectRepairsList,
  (allRepairs) => {
    // WE ONLY REQUIRE THESE REPAIRS IN THIS ORDER
    const popularRepairIds = [23, 12, 9, 5, 580, 16, 57, 6, 164];
    const repairsReturn = filterRepairs(allRepairs ?? [], popularRepairIds);

    // FIND ONE MORE
    // PACKAGES DON'T HAVE STATIC IDS BETWEEN ENVIRONMENTS
    const brakePads = allRepairs?.find(
      (r) => r.repairName === 'Replace Front and Rear Brake Pads'
    );
    if (brakePads) {
      return arrayInsert(repairsReturn, brakePads, 2);
    }
    return repairsReturn;
  }
);

export const selectSymptomTree = createSelector(
  selectRepairs,
  (repairs) => repairs?.symptomTree
);

export const selectFavoriteCommonRepairs = createSelector(
  selectRepairsList,
  (repairs) =>
    favoriteCommonRepairsData.map((favRepair) => {
      const foundRepair = repairs?.find(
        (repair) => repair.repairId[0] === favRepair.id
      );
      if (!foundRepair) return null;
      return {
        nodeId: favRepair.id,
        icon: favRepair.icon,
        name: foundRepair.repairName,
      };
    })
);

export const selectSearchResults = createSelector(
  selectRepairs,
  (repairs): SearchRepair[] => repairs?.searchResults?.results ?? []
);

export const selectRecommendedRepairs = createSelector(
  selectRepairs,
  (repairs: any) =>
    (repairs?.recommended ?? []).map((repair: RepairSimple) => ({
      repairId: [repair.id],
      repairName: repair.name,
    }))
);

export const selectAllRecommendedRepairs = createSelector(
  selectRepairs,
  (repairs) => repairs?.allRecommendedRepairs || []
);

export const selectRecommendedRepairsForCurrentCar = createSelector(
  selectRepairs,
  (repairs) => repairs?.recommendedRepairsCar || []
);

export const selectCarsWithRecommendedRepairs = createSelector(
  selectAllRecommendedRepairs,
  (recommendedRepairs: RecommendedRepair[]) => {
    const carsById: { [id: number]: CarWithRecommendedRepairs } = {};

    recommendedRepairs.forEach((repair) => {
      const { car } = repair;
      if (!carsById[car.consumerCarId as number]) {
        carsById[car.consumerCarId as number] = {
          car,
          repairs: [],
          mpiId: repair.mpiId,
        };
      }

      carsById[car.consumerCarId as number].repairs.push(repair);
    });

    return Object.keys(carsById).map((id) => {
      const car = carsById[id as any];
      return {
        ...car,
        repairs: orderBy(
          car.repairs,
          (repair: RecommendedRepair) => moment(repair.requestDate),
          ['desc']
        ),
        mpiId: car.mpiId,
      };
    });
  }
);

export const checkCarsForNoRepairs = createSelector(
  selectCarsWithRecommendedRepairs,
  (cars: CarWithRecommendedRepairs[]) => {
    return every(cars, (car) => !getCarServicesNum(car?.repairs));
  }
);
