import { handleActions } from 'redux-actions';
import { createSimpleHandler } from 'store/utils/createSimpleHandler';
import {
  getRepairsList,
  searchRepairs,
  getSymptomTree,
  getRecommendedRepairs,
  getAllRecommendedRepairs,
  getRecommendedRepairsCar,
} from 'store/repairs/actions';
import { signOut } from 'store/auth/actions';
import { Repair } from 'entities/Repair';
import { SymptomTreeChildNode } from 'entities/SymptomTreeChildNode';
import { RepairSimple } from 'entities/RepairSimple';
import { SearchResults } from 'entities/SearchResults';
import { Car } from 'entities/Car';

export type RecommendedRepair = {
  car: Car;
  requestDate: string;
  recommendedServices: {
    subTotal: number | null;
    recommendedRepairs: any[];
    rejectedRepairs: any[];
    createdRepairs: any[];
    scheduledRepairs: any[];
  };
  mpiId: string | null;
  referenceNum: string;
};

export interface RepairsStoreState {
  allRepairs?: Repair[];
  searchResults?: SearchResults;
  symptomTree?: SymptomTreeChildNode;
  recommendedRepairs?: RepairSimple[];
  allRecommendedRepairs?: RecommendedRepair[];
  recommendedRepairsCar?: RecommendedRepair[];
}

const defaultState: RepairsStoreState = {};

const reducer = handleActions<RepairsStoreState>(
  {
    [getRepairsList?.typeAPISuccess]: createSimpleHandler('allRepairs'),
    [searchRepairs?.typeAPISuccess]: createSimpleHandler('searchResults'),
    [getSymptomTree?.typeAPISuccess]: createSimpleHandler('symptomTree'),
    [getRecommendedRepairs?.typeAPISuccess]: createSimpleHandler('recommended'),
    [getAllRecommendedRepairs?.typeAPISuccess]: createSimpleHandler(
      'allRecommendedRepairs',
      'content'
    ),
    [getRecommendedRepairsCar?.typeAPISuccess]: createSimpleHandler(
      'recommendedRepairsCar',
      'content'
    ),
    [signOut?.type]: () => ({ ...defaultState }),
  },
  defaultState
);

export default reducer;
