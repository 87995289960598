import React from 'react';

type LogoStackedProps = {
  className?: string;
  alt?: string | null;
};

const LogoStacked = ({ className = '', alt = 'logo' }: LogoStackedProps) => {
  return (
    <svg
      width="160"
      height="50"
      viewBox="0 0 160 50"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{alt}</title>
      <g id="Mobile Service Logo_WHITE-PINK 1" clipPath="url(#clip0_399_1800)">
        <path
          id="Vector"
          d="M38.1529 32.3696C37.9015 32.3696 37.7858 32.2917 37.7858 32.1382C37.7858 32.0403 37.8058 31.9647 37.8436 31.849L40.0463 26.9609L32.4149 31.849C31.8543 32.2161 31.3715 32.3696 30.9843 32.3696H29.863C29.4759 32.3696 29.091 32.2139 28.7995 31.849L24.877 26.9609L22.6944 31.849C22.5409 32.1961 22.2895 32.3696 21.9402 32.3696H20.1425C19.8911 32.3696 19.7754 32.2917 19.7754 32.1382C19.7754 32.0403 19.7954 31.9647 19.8533 31.849L25.2642 19.6945C25.4177 19.3652 25.6691 19.1917 26.0184 19.1917H27.5447C27.9496 19.1917 28.3167 19.3652 28.5503 19.6945L33.9612 27.8865L47.2927 19.6945C47.8534 19.3652 48.414 19.1917 48.8768 19.1917H50.4231C50.6545 19.1917 50.7702 19.2673 50.7702 19.4431C50.7702 19.5209 50.7702 19.5966 50.7123 19.6945L45.2836 31.849C45.1301 32.1961 44.8965 32.3696 44.5494 32.3696H38.1529Z"
          fill="currentColor"
        />
        <path
          id="Vector_2"
          d="M53.1531 32.3696C50.4677 32.3696 49.0193 30.5719 49.0193 28.1957C49.0193 23.6748 53.8896 19.1917 59.029 19.1917H68.4981C71.2236 19.1917 72.672 20.9293 72.672 23.3077C72.672 27.8487 67.6682 32.3696 62.6244 32.3696H53.1553H53.1531ZM64.7848 24.4668C65.2097 23.5569 65.4233 22.9028 65.4233 22.4378C65.4233 21.7035 64.8827 21.4521 63.8592 21.4521H61.2317C59.7254 21.4521 58.7976 22.1285 57.772 24.4668L56.8442 26.5738C56.3414 27.6951 56.11 28.4872 56.11 29.0479C56.11 29.8021 56.555 30.0913 57.3848 30.0913H60.0324C61.6166 30.0913 62.7001 29.2036 63.8592 26.576L64.787 24.4691L64.7848 24.4668Z"
          fill="currentColor"
        />
        <path
          id="Vector_3"
          d="M70.8765 32.3696C70.6451 32.3696 70.5294 32.2917 70.5294 32.1382C70.5294 32.0403 70.5494 31.9647 70.5873 31.849L76.016 19.6945C76.1695 19.3652 76.4031 19.1917 76.7502 19.1917H93.5213C95.3568 19.1917 96.2446 19.9837 96.2446 21.2586C96.2446 23.6748 94.1977 24.5825 93.2499 24.9875C93.7327 25.181 94.1977 25.9531 94.1977 27.0344C94.1977 29.3727 91.5323 32.3674 87.3184 32.3674H70.8743L70.8765 32.3696ZM82.2189 26.3224C81.9875 26.3224 81.8897 26.2445 81.8897 26.091C81.8897 26.0154 81.9097 25.9175 81.9475 25.8196L82.5082 24.5625C82.6617 24.2332 82.8953 24.0597 83.2424 24.0597H86.3728C87.6677 24.0597 88.8068 23.0162 88.8068 22.2442C88.8068 21.7792 88.4597 21.4521 87.5319 21.4521H82.6817L78.8371 30.0891H83.3581C86.1014 30.0891 86.5263 28.06 86.5263 27.4037C86.5263 26.7073 86.0435 26.3224 85.0379 26.3224H82.2167H82.2189Z"
          fill="currentColor"
        />
        <path
          id="Vector_4"
          d="M94.4757 32.3696C94.2444 32.3696 94.1287 32.2917 94.1287 32.1382C94.1287 32.0403 94.1487 31.9647 94.1865 31.849L99.6152 19.6945C99.771 19.3652 100.02 19.1917 100.349 19.1917H106.995C107.247 19.1917 107.362 19.2673 107.362 19.4431C107.362 19.5209 107.342 19.5966 107.284 19.6945L101.873 31.849C101.718 32.1961 101.469 32.3696 101.139 32.3696H94.4735H94.4757Z"
          fill="currentColor"
        />
        <path
          id="Vector_5"
          d="M109.298 32.3696C106.612 32.3696 105.393 31.2683 105.393 29.5106C105.393 28.7564 105.625 27.8687 106.05 26.9209L109.276 19.6945C109.429 19.3652 109.663 19.1917 110.01 19.1917H117.41C117.661 19.1917 117.777 19.2673 117.777 19.4431C117.777 19.5209 117.757 19.5966 117.701 19.6945L114.339 27.2502C113.992 28.0222 113.817 28.6029 113.817 29.0479C113.817 29.7621 114.299 30.0913 115.343 30.0913H122.936C123.188 30.0913 123.283 30.1892 123.283 30.3427C123.283 30.4006 123.263 30.5163 123.206 30.6142L122.665 31.8512C122.509 32.1983 122.26 32.3718 121.931 32.3718H109.293L109.298 32.3696Z"
          fill="currentColor"
        />
        <path
          id="Vector_6"
          d="M128.663 32.3696C126.036 32.3696 124.547 30.632 124.547 28.1957C124.547 23.6926 129.435 19.1917 134.537 19.1917H148.044C148.276 19.1917 148.391 19.2673 148.391 19.4431C148.391 19.5209 148.373 19.5966 148.333 19.6945L147.773 20.9315C147.619 21.2786 147.368 21.4521 147.019 21.4521H138.034C136.372 21.4521 135.465 22.0907 134.421 24.4668L133.493 26.5738C133.011 27.6551 132.759 28.4294 132.759 28.99C132.759 29.7821 133.262 30.0913 134.19 30.0913H143.252C143.483 30.0913 143.599 30.1892 143.599 30.3427C143.599 30.4006 143.579 30.5163 143.521 30.6142L142.98 31.8512C142.825 32.1983 142.575 32.3718 142.226 32.3718H128.661L128.663 32.3696ZM135.485 26.3224C135.253 26.3224 135.138 26.2445 135.138 26.091C135.138 26.0154 135.158 25.9175 135.216 25.8196L135.756 24.5625C135.912 24.2332 136.161 24.0597 136.51 24.0597H145.728C146 24.0597 146.133 24.1175 146.133 24.2533C146.133 24.2911 146.113 24.3511 146.095 24.409L145.381 25.9731C145.285 26.2045 145.052 26.3202 144.725 26.3202H135.489L135.485 26.3224Z"
          fill="currentColor"
        />
        <path
          id="Vector_7"
          d="M12.1219 48.2375C11.8905 48.2375 11.7748 48.1597 11.7748 48.0061C11.7748 47.9082 11.7948 47.8326 11.8326 47.7169L12.3933 46.4799C12.5468 46.1328 12.7982 45.957 13.1475 45.957H24.1807L17.0121 41.8232C15.873 41.1669 15.5059 40.3548 15.5059 39.5805C15.5059 37.3979 17.9021 35.0596 21.1081 35.0596H35.3496C35.601 35.0596 35.7167 35.1352 35.7167 35.311C35.7167 35.3889 35.6967 35.4645 35.6388 35.5624L35.0982 36.7994C34.9447 37.1465 34.6732 37.32 34.344 37.32H23.6401L30.8086 41.4561C31.9878 42.1325 32.3171 42.9623 32.3171 43.7166C32.3171 45.8992 29.9587 48.2375 26.7126 48.2375H12.1219Z"
          fill="#E31566"
        />
        <path
          id="Vector_8"
          d="M38.0395 48.2375C35.4119 48.2375 33.9235 46.4999 33.9235 44.0637C33.9235 39.5627 38.8115 35.0596 43.9132 35.0596H57.4204C57.6518 35.0596 57.7675 35.1352 57.7675 35.311C57.7675 35.3889 57.7475 35.4645 57.7096 35.5624L57.149 36.7994C56.9955 37.1465 56.744 37.32 56.3947 37.32H47.4107C45.7487 37.32 44.8409 37.9586 43.7975 40.3348L42.8697 42.4417C42.3869 43.523 42.1355 44.2973 42.1355 44.8579C42.1355 45.65 42.6383 45.9593 43.5661 45.9593H52.628C52.8594 45.9593 52.9751 46.0572 52.9751 46.2107C52.9751 46.2685 52.9551 46.3842 52.8972 46.4821L52.3566 47.7191C52.2031 48.0662 51.9517 48.2398 51.6024 48.2398H38.0373L38.0395 48.2375ZM44.861 42.1903C44.6296 42.1903 44.5139 42.1124 44.5139 41.9589C44.5139 41.8833 44.5339 41.7854 44.5918 41.6875L45.1324 40.4304C45.2859 40.1011 45.5373 39.9276 45.8866 39.9276H55.1043C55.3757 39.9276 55.5092 39.9855 55.5092 40.1212C55.5092 40.159 55.4892 40.2191 55.4714 40.2769L54.7572 41.841C54.6616 42.0724 54.4279 42.1881 54.1009 42.1881H44.8654L44.861 42.1903Z"
          fill="#E31566"
        />
        <path
          id="Vector_9"
          d="M70.2935 48.2375C69.7907 48.2375 69.4236 48.084 69.23 47.7169L66.0039 41.5918C65.9261 41.4761 65.8682 41.3804 65.8682 41.2247C65.8682 41.1468 65.906 41.0311 65.9839 40.8776L66.1975 40.4326C66.351 40.1033 66.5846 39.9298 66.9317 39.9298H69.5593C71.2413 39.9298 72.109 39.1177 72.109 38.1922C72.109 37.6515 71.664 37.3222 70.7184 37.3222H66.4088L61.7722 47.7191C61.6187 48.0662 61.3851 48.2397 61.038 48.2397H54.4101C54.1787 48.2397 54.043 48.1619 54.043 48.0083C54.043 47.9104 54.063 47.8348 54.1208 47.7191L59.5495 35.5646C59.7031 35.2353 59.9367 35.0618 60.2838 35.0618H77.1706C78.9683 35.0618 80.0696 36.0096 80.0696 37.4001C80.0696 39.932 77.4621 41.7676 74.3116 42.1547L77.2284 47.6234C77.2485 47.6613 77.2663 47.7391 77.2663 47.797C77.2663 48.0106 77.0549 48.242 76.7456 48.242H70.2913L70.2935 48.2375Z"
          fill="#E31566"
        />
        <path
          id="Vector_10"
          d="M90.9538 47.8526C90.6067 48.104 90.2774 48.2398 89.9682 48.2398H88.6933C88.3462 48.2398 88.1527 48.104 88.0169 47.8526L81.4869 35.7181C81.4491 35.6603 81.4491 35.5624 81.4491 35.5045C81.4491 35.2531 81.6427 35.0596 82.0676 35.0596H90.0082C90.4332 35.0596 90.7046 35.1753 90.8403 35.4445L94.8584 43.1937L106.123 35.4445C106.49 35.1931 106.857 35.0596 107.302 35.0596H108.559C108.733 35.0596 108.811 35.1352 108.811 35.2709C108.811 35.4067 108.695 35.5424 108.501 35.6959L90.956 47.8482L90.9538 47.8526Z"
          fill="#E31566"
        />
        <path
          id="Vector_11"
          d="M104.777 48.2375C104.546 48.2375 104.43 48.1597 104.43 48.0061C104.43 47.9082 104.45 47.8326 104.488 47.7169L109.916 35.5624C110.072 35.2331 110.321 35.0596 110.651 35.0596H117.296C117.548 35.0596 117.663 35.1352 117.663 35.311C117.663 35.3889 117.643 35.4645 117.586 35.5624L112.175 47.7169C112.019 48.064 111.77 48.2375 111.44 48.2375H104.775H104.777Z"
          fill="#E31566"
        />
        <path
          id="Vector_12"
          d="M120.102 48.2375C117.474 48.2375 115.986 46.4999 115.986 44.0637C115.986 39.5627 120.874 35.0596 125.976 35.0596H139.133C139.365 35.0596 139.481 35.1352 139.481 35.311C139.481 35.3889 139.481 35.4645 139.423 35.5624L138.862 36.7994C138.706 37.1465 138.457 37.32 138.108 37.32H129.471C127.809 37.32 126.901 37.9586 125.858 40.3348L124.93 42.4417C124.447 43.523 124.196 44.2973 124.196 44.8579C124.196 45.65 124.698 45.9593 125.626 45.9593H134.341C134.593 45.9593 134.688 46.0572 134.688 46.2107C134.688 46.2685 134.668 46.3842 134.61 46.4821L134.07 47.7191C133.916 48.0662 133.665 48.2398 133.335 48.2398H120.1L120.102 48.2375Z"
          fill="#E31566"
        />
        <path
          id="Vector_13"
          d="M140.277 48.2375C137.65 48.2375 136.161 46.4999 136.161 44.0637C136.161 39.5627 141.049 35.0596 146.151 35.0596H159.658C159.889 35.0596 160.005 35.1352 160.005 35.311C160.005 35.3889 159.985 35.4645 159.947 35.5624L159.387 36.7994C159.233 37.1465 158.982 37.32 158.632 37.32H149.648C147.986 37.32 147.079 37.9586 146.035 40.3348L145.107 42.4417C144.625 43.523 144.373 44.2973 144.373 44.8579C144.373 45.65 144.876 45.9593 145.804 45.9593H154.866C155.097 45.9593 155.213 46.0572 155.213 46.2107C155.213 46.2685 155.193 46.3842 155.135 46.4821L154.594 47.7191C154.439 48.0662 154.189 48.2398 153.84 48.2398H140.275L140.277 48.2375ZM147.099 42.1903C146.867 42.1903 146.752 42.1124 146.752 41.9589C146.752 41.8833 146.772 41.7854 146.829 41.6875L147.37 40.4304C147.526 40.1011 147.775 39.9276 148.124 39.9276H157.342C157.613 39.9276 157.747 39.9855 157.747 40.1212C157.747 40.159 157.727 40.2191 157.709 40.2769L156.995 41.841C156.897 42.0724 156.666 42.1881 156.339 42.1881H147.103L147.099 42.1903Z"
          fill="#E31566"
        />
        <path
          id="Vector_14"
          d="M37.1272 12.4637L36.2306 9.92064H32.0567L31.1601 12.4637H28.715L33.0846 1.05005H35.3028L39.6414 12.4637H37.1295H37.1272ZM34.7132 5.68002C34.4863 5.01256 34.2571 4.3273 34.1437 3.90234C34.0302 4.3273 33.801 5.01033 33.5741 5.68002L32.8399 7.75139H35.4497L34.7155 5.68002H34.7132Z"
          fill="currentColor"
        />
        <path
          id="Vector_15"
          d="M45.2503 12.4637V11.8919C44.8587 12.299 44.1423 12.6416 43.2457 12.6416C41.0609 12.6416 40.0819 11.1421 40.0819 8.72808V3.90454H42.3313V8.53452C42.3313 10.0675 42.8519 10.5236 43.7819 10.5236C44.7119 10.5236 45.2481 10.0675 45.2481 8.58346V3.90454H47.4974V12.4659H45.2481L45.2503 12.4637Z"
          fill="currentColor"
        />
        <path
          id="Vector_16"
          d="M53.1397 12.1211C52.7637 12.4148 52.0962 12.6439 51.3954 12.6439C50.0093 12.6439 49.1127 11.5671 49.1127 10.0675V1.70196L51.362 0.576172V3.90237H53.4156V6.02268H51.362V9.46457C51.362 10.2789 51.6557 10.4925 52.1941 10.4925C52.6013 10.4925 52.9105 10.3122 53.4668 9.84057L53.1419 12.1233L53.1397 12.1211Z"
          fill="currentColor"
        />
        <path
          id="Vector_17"
          d="M57.8342 12.6439C55.6004 12.6439 53.9696 10.8173 53.9696 8.19192C53.9696 5.56657 55.6004 3.72437 57.8342 3.72437C60.068 3.72437 61.7144 5.56657 61.7144 8.19192C61.7144 10.8173 60.068 12.6439 57.8342 12.6439ZM57.8342 5.89362C56.7574 5.89362 56.2523 6.87257 56.2523 8.19192C56.2523 9.51128 56.7574 10.4746 57.8342 10.4746C58.9111 10.4746 59.4317 9.38223 59.4317 8.19192C59.4317 6.88814 58.9111 5.89362 57.8342 5.89362Z"
          fill="currentColor"
        />
        <path
          id="Vector_18"
          d="M70.2267 12.4637L66.0373 6.23402C65.7614 5.80907 65.3854 5.2395 65.2386 4.94581C65.2386 5.37077 65.2719 6.80581 65.2719 7.4399V12.4615H63.0226V1.05005H65.2074L69.25 7.08392C69.5281 7.50888 69.9019 8.07844 70.0488 8.37213C70.0488 7.94718 70.0154 6.51435 70.0154 5.87804V1.05005H72.2647V12.4637H70.2267Z"
          fill="currentColor"
        />
        <path
          id="Vector_19"
          d="M78.7236 12.4637V11.892C78.3164 12.3169 77.5978 12.6417 76.7991 12.6417C75.2016 12.6417 73.6041 11.6628 73.6041 9.59143C73.6041 7.70028 75.1371 6.75471 77.0772 6.75471C77.8603 6.75471 78.3654 6.91712 78.7236 7.09734V6.67239C78.7236 6.06944 78.3164 5.57997 77.4999 5.57997C76.472 5.57997 75.9336 5.72681 75.1526 6.14954L74.2872 4.58545C75.2817 4.01365 76.1138 3.71997 77.4999 3.71997C79.569 3.71997 80.9729 4.79681 80.9729 6.72133V12.4615H78.7236V12.4637ZM78.7236 8.87724C78.332 8.6325 77.9582 8.51904 77.1573 8.51904C76.3096 8.51904 75.8513 8.91062 75.8513 9.59588C75.8513 10.1988 76.1939 10.7372 77.1573 10.7372C77.8915 10.7372 78.4788 10.3612 78.7236 9.95408V8.87724Z"
          fill="currentColor"
        />
        <path
          id="Vector_20"
          d="M86.5307 12.1211C86.1547 12.4148 85.4872 12.6439 84.7864 12.6439C83.4003 12.6439 82.5037 11.5671 82.5037 10.0675V1.70196L84.753 0.576172V3.90237H86.8066V6.02268H84.753V9.46457C84.753 10.2789 85.0467 10.4925 85.5851 10.4925C85.9923 10.4925 86.3015 10.3122 86.8578 9.84057L86.5329 12.1233L86.5307 12.1211Z"
          fill="currentColor"
        />
        <path
          id="Vector_21"
          d="M88.2239 12.4637V3.90454H90.4732V12.4659H88.2239V12.4637Z"
          fill="currentColor"
        />
        <path
          id="Vector_22"
          d="M95.3524 12.6439C93.1186 12.6439 91.4878 10.8173 91.4878 8.19192C91.4878 5.56657 93.1186 3.72437 95.3524 3.72437C97.5862 3.72437 99.2326 5.56657 99.2326 8.19192C99.2326 10.8173 97.5862 12.6439 95.3524 12.6439ZM95.3524 5.89362C94.2756 5.89362 93.7705 6.87257 93.7705 8.19192C93.7705 9.51128 94.2756 10.4746 95.3524 10.4746C96.4292 10.4746 96.9499 9.38223 96.9499 8.19192C96.9499 6.88814 96.4292 5.89362 95.3524 5.89362Z"
          fill="currentColor"
        />
        <path
          id="Vector_23"
          d="M105.331 12.4637V7.83372C105.331 6.30078 104.81 5.84468 103.88 5.84468C102.95 5.84468 102.412 6.30078 102.412 7.78477V12.4637H100.163V3.90458H102.412V4.47415C102.804 4.067 103.52 3.72437 104.417 3.72437C106.601 3.72437 107.58 5.22393 107.58 7.63793V12.4637H105.331Z"
          fill="currentColor"
        />
        <path
          id="Vector_24"
          d="M108.541 12.4459C108.261 12.4459 108.047 12.2323 108.047 11.9519C108.047 11.6716 108.261 11.458 108.541 11.458C108.822 11.458 109.035 11.6716 109.035 11.9519C109.035 12.2323 108.822 12.4459 108.541 12.4459ZM108.541 11.5381C108.306 11.5381 108.128 11.7161 108.128 11.9519C108.128 12.1878 108.306 12.3658 108.541 12.3658C108.777 12.3658 108.955 12.1878 108.955 11.9519C108.955 11.7161 108.777 11.5381 108.541 11.5381ZM108.653 11.9942L108.764 12.2122H108.655L108.55 12.0053H108.446V12.2122H108.355V11.6738H108.59C108.686 11.6738 108.766 11.725 108.766 11.8362C108.766 11.9208 108.722 11.9742 108.65 11.9942H108.653ZM108.588 11.7628H108.446V11.9163H108.588C108.637 11.9163 108.675 11.8941 108.675 11.8407C108.675 11.7895 108.637 11.765 108.588 11.765V11.7628Z"
          fill="currentColor"
        />
        <path
          id="Vector_25"
          d="M97.7508 14.1814C95.3568 14.1814 92.9651 14.2036 90.5711 14.2548C87.2205 14.326 83.8676 14.4261 80.5191 14.5952C77.2597 14.7599 74.0025 14.9356 70.7475 15.1759C68.2022 15.3628 65.6592 15.5719 63.1139 15.7855C63.1851 15.7811 63.2585 15.7766 63.3297 15.7744C66.0685 15.6231 68.8051 15.483 71.544 15.3606C74.6855 15.2182 77.8315 15.1537 80.9752 15.0869C82.9153 15.0447 84.8554 15.0291 86.7955 15.0291C88.0904 15.0291 89.3875 15.0358 90.6824 15.0447C93.7994 15.0691 96.9165 15.1381 100.034 15.2493C101.457 15.3005 102.881 15.3628 104.303 15.4385C104.87 15.4674 105.436 15.4985 106.003 15.5363C106.308 15.5564 106.61 15.5764 106.915 15.5964C107 15.6031 107.113 15.6209 107.222 15.6209C107.396 15.6209 107.558 15.5719 107.571 15.3361C107.58 15.1603 107.589 14.9868 107.598 14.811C107.598 14.7888 107.598 14.7688 107.598 14.7465C107.598 14.5263 107.531 14.3683 107.296 14.3505C107.017 14.3305 106.735 14.3282 106.457 14.3193C105.84 14.2971 105.226 14.2771 104.61 14.2615C103.157 14.2259 101.707 14.1992 100.254 14.1903C99.4195 14.1858 98.5829 14.1814 97.7486 14.1814"
          fill="currentColor"
        />
        <path
          id="Vector_26"
          d="M89.0582 16.0903C88.3329 16.0903 87.6053 16.0926 86.88 16.1015C84.3993 16.1259 81.9185 16.1571 79.4378 16.2305C77.4977 16.2883 75.5598 16.3595 73.6219 16.4374C73.6776 16.4374 73.731 16.4374 73.7866 16.4352C75.8713 16.4063 77.956 16.3862 80.0407 16.3751C80.3255 16.3751 80.6125 16.3751 80.8973 16.3751C83.0043 16.3751 85.109 16.4107 87.216 16.4441C89.6767 16.4841 92.1374 16.5731 94.5959 16.6755C96.9654 16.7734 99.3349 16.9046 101.7 17.0604C102.781 17.1316 103.863 17.2117 104.942 17.3007C105.371 17.3363 105.8 17.3719 106.232 17.4119C106.463 17.4342 106.693 17.4542 106.924 17.4764C106.995 17.4831 107.093 17.5009 107.182 17.5009C107.304 17.5009 107.416 17.4653 107.429 17.3185C107.44 17.2005 107.449 17.0849 107.46 16.9669C107.46 16.9469 107.462 16.9247 107.462 16.9046C107.462 16.7667 107.411 16.6666 107.242 16.651C107.031 16.631 106.817 16.6221 106.604 16.6065C106.136 16.5754 105.669 16.5464 105.202 16.5197C104.098 16.4574 102.995 16.4018 101.891 16.3573C99.4372 16.2572 96.9832 16.1838 94.5269 16.1437C92.7047 16.1148 90.8803 16.0948 89.0582 16.0948"
          fill="#E31566"
        />
        <path
          id="Vector_27"
          d="M1.48245 49.3478C0.496825 49.3478 0.00512695 49.0207 0.00512695 48.3622C0.00512695 47.9528 0.0874475 47.6213 0.249864 47.1296L19.5729 2.63198C20.2315 1.23253 21.2994 0.496094 22.6945 0.496094H25.589C26.657 0.496094 27.1487 0.823151 27.1487 1.56404C27.1487 1.89109 27.0663 2.21815 26.8172 2.63198L7.57417 47.1296C6.9156 48.6069 5.84766 49.3478 4.45266 49.3478H1.48245Z"
          fill="#E31566"
        />
      </g>
      <defs>
        <clipPath id="clip0_399_1800">
          <rect
            width="160"
            height="48.8517"
            fill="white"
            transform="translate(0.00512695 0.496094)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoStacked;
