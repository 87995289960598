import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import FleetContactSupport from './components/common/FleetLayout/components/FleetContactSupport';
import { setCurrentFunnel } from './store/funnel/actions';
import { affirmScript } from './utilities/affirmScript';
import { getRandomNonce } from './utilities/crypto';

const {
  GOOGLE_PLACES_API_KEY,
  AFFIRM_API_KEY,
  AFFIRM_ENABLED,
  AFFIRM_API_URL,
  ZENDESK_CHAT_ENABLED,
  ZENDESK_FLEET_CHAT_ENABLED,
  ZENDESK_CHAT_API_KEY,
} = window.RepairSmith;
const affirmEnabled = AFFIRM_ENABLED === 'true';
const zendeskChatEnabled = ZENDESK_CHAT_ENABLED === 'true';
const zendeskFleetChatEnabled = ZENDESK_FLEET_CHAT_ENABLED === 'true';

type DocumentProps = {
  children: React.ReactNode;
};

const Document = ({ children }: DocumentProps) => {
  const cspNonce = getRandomNonce();
  const dispatch = useDispatch();

  useEffect(() => {
    if (affirmEnabled) {
      affirmScript(AFFIRM_API_KEY, AFFIRM_API_URL);
    }
  }, []);

  const savedCustomFunnel = sessionStorage.getItem('partnerFunnel');
  if (savedCustomFunnel) {
    dispatch(setCurrentFunnel(JSON.parse(savedCustomFunnel)));
  }

  window.zESettings = {
    webWidget: {
      chat: {
        suppress: true,
        departments: {
          enabled: [],
        },
      },
      contactForm: {
        suppress: true,
      },
      helpCenter: {
        suppress: true,
      },
      talk: {
        suppress: true,
      },
      answerBot: {
        suppress: true,
      },
    },
  };

  /* OneTrust Cookies Consent Notice start for autonationmobileservice.com */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const OptanonWrapper = () => {};

  return (
    <>
      <Helmet>
        <script
          async
          nonce={cspNonce}
          type="text/javascript"
          src="https://cdn.jsdelivr.net/gh/rspective/voucherify.js@latest/dist/voucherify.min.js"
        />
        <script
          async
          nonce={cspNonce}
          type="text/javascript"
          src={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_PLACES_API_KEY}&libraries=places&callback=googleApiLoadedCallback&language=en`}
        />
        <script
          async
          nonce={cspNonce}
          type="text/javascript"
          src="https://core.spreedly.com/iframe/iframe-v1.min.js"
        />
        {zendeskChatEnabled && (
          <script
            async
            nonce={cspNonce}
            type="text/javascript"
            id="ze-snippet"
            src={`https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_CHAT_API_KEY}`}
          />
        )}
        <script>window.InvocaTagId = '1649/4209653272';</script>
        <script
          async
          nonce={cspNonce}
          type="text/javascript"
          src="https://solutions.invocacdn.com/js/invoca-latest.min.js"
        />

        {/* OneTrust Cookies Consent Notice start for autonationmobileservice.com */}
        {window.RepairSmith.APP_ENV !== 'local' && (
          <script
            src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
            type="text/javascript"
            data-domain-script="638c4380-133c-4fbd-9683-9de8425229fa"
          />
        )}
      </Helmet>
      {children}

      {zendeskFleetChatEnabled && <FleetContactSupport />}
    </>
  );
};

export default Document;
