import { createAPIAction } from 'store/utils/createAPIAction';

const serviceName = 'home';

export const getMakesStatic = createAPIAction(
  `${serviceName}/GET_MAKES_STATIC`,
  (data: any) => {
    return {
      endpoint: `/autoselection-service/v2/${data}`,
    };
  }
);
