import { handleActions } from 'redux-actions';
import { createSimpleHandler } from 'store/utils/createSimpleHandler';
import { createClearHandler } from 'store/utils/createClearHandler';
import {
  getRepairRequestShared,
  getAppointment,
  setAppointment,
  clearCancelStatus,
  clearRescheduleStatus,
  updateInvoiceDocuments,
  uploadInvoiceDocuments,
  deleteInvoiceDocumentById,
  updateServices,
  submitAllRepairs,
  clearRepairRequest,
  submitAppointmentRequest,
  updateAppointmentFAA,
  getCancelResolutions,
  setRequestFunnel,
} from 'store/repairRequest/actions';
import { set, get } from 'lodash';
import { signOut } from 'store/auth/actions';
import { InvoiceDocument, TrackerState } from 'entities/RepairRequest';
import { Car } from 'entities/Car';
import createAPIHandlers from 'store/utils/createAPIHandler';
import { KnowRepair } from 'entities/KnowRepair';
import { Diagnose } from 'entities/Diagnose';
import { CustomRequest } from 'entities/CustomRequest';
import Funnel from 'entities/Funnel';
import { updateVinAndQuote } from '../consumer/actions';

export type EditRepairRequestResponse = {
  diagnosisServices: {
    knownRepairs: KnowRepair[];
    removedRepairs: KnowRepair[];
    diagnoses: Diagnose[];
    removedDiagnoses: Diagnose[];
    customRequests: CustomRequest[];
    removedCustomRequests: CustomRequest[];
    removedComponents: unknown[];
    removedCustomComponents: unknown[];
  };
  totalCost: number | null;
};

export type RepairRequestShared = {
  referenceNum: string;
  repairRequestStatus: string;
  repairRequestState: string;
  car: Car;
  appointment: any;
  diagnosis: Diagnose;
  trackerState: TrackerState;
  marketingName: string;
  firstNameMissing: boolean;
  lastNameMissing: boolean;
  phoneNumberMissing: boolean;
  repairRequest: any;
  defaultDeliveryFee: number;
};

export interface RepairRequestStoreState {
  repairRequestShared?: RepairRequestShared;
  submitAllRepairs: {
    data: EditRepairRequestResponse | null;
    requesting: boolean;
    errors: any;
  };
  appointment: any;
  tax?: any;
  funnel?: Funnel;
}

export interface IResolution {
  id: string;
  issuer: string;
  reason: string;
  specificReason?: string;
}

const defaultState: RepairRequestStoreState = {
  submitAllRepairs: {
    data: null,
    requesting: false,
    errors: null,
  },
  appointment: undefined,
  tax: undefined,
  funnel: undefined,
};

const reducer = handleActions<RepairRequestStoreState, any>(
  {
    [getRepairRequestShared?.typeAPISuccess]: createSimpleHandler(
      'repairRequestShared'
    ),
    [getAppointment?.typeAPISuccess]: createSimpleHandler('appointment'),
    [setAppointment?.type]: createSimpleHandler('appointment'),
    [uploadInvoiceDocuments?.typeAPISuccess]: (
      state,
      { payload }: { payload: InvoiceDocument[] }
    ) =>
      set(
        { ...state },
        'appointment.repairRequest.workOrder.documents',
        payload
      ),
    [updateInvoiceDocuments?.typeAPISuccess]: (
      state,
      { payload }: { payload: InvoiceDocument[] }
    ) => {
      return set(
        { ...state },
        'appointment.repairRequest.workOrder.documents',
        [
          ...get(state, 'appointment.repairRequest.workOrder.documents', []),
          ...payload,
        ]
      );
    },
    [updateVinAndQuote?.typeAPISuccess]: (
      state,
      { payload }: { payload: Car }
    ) => {
      return set({ ...state }, 'repairRequest.car', payload);
    },
    [deleteInvoiceDocumentById?.type]: (
      state,
      { payload }: { payload: { documentId: number } }
    ) => {
      return set(
        { ...state },
        'appointment.invoice.documents',
        get(state, 'appointment.invoice.documents', []).filter(
          (doc: InvoiceDocument) => doc.id !== payload.documentId
        )
      );
    },
    [clearCancelStatus?.type]: createClearHandler('cancelAppointment', null),
    [updateServices?.type]: (
      state,
      {
        payload,
      }: {
        payload: {
          services: any;
          totalCost: number;
        };
      }
    ) => {
      if (state.appointment) {
        return {
          ...state,
          repairRequest: {
            ...state.appointment,
            services: payload.services,
            totalCost: payload.totalCost,
          },
        };
      }
      return state;
    },
    [clearRescheduleStatus?.type]: createClearHandler(
      'rescheduleAppointmentRequestStatus',
      null
    ),
    [clearRepairRequest?.type]: () => ({ ...defaultState }),
    [getCancelResolutions?.typeAPISuccess]: createSimpleHandler('resolutions'),
    [submitAppointmentRequest?.typeAPISuccess]: (state, { payload }) => {
      return set(
        { ...state },
        'appointment.repairRequest.activeAppointment.id',
        payload.appointmentId
      );
    },
    [updateAppointmentFAA?.typeAPISuccess]: (state, { payload }) => {
      return set(
        { ...state },
        'appointment.repairRequest.activeAppointment.id',
        payload.appointmentId
      );
    },
    [setRequestFunnel?.type]: createSimpleHandler('funnel'),
    [signOut?.type]: () => ({ ...defaultState }),
    ...createAPIHandlers(submitAllRepairs, 'submitAllRepairs'),
  },
  defaultState
);

export default reducer;
