import numeral from 'numeral';
import moment from 'moment';

export const commaFormat = (val: string | number) => numeral(val).format('0,0');

export const toMoneySansSign = (val: string | number) =>
  `${numeral(val).format('0,0[.]00')}`;
export const usMoneyBaseFormat = (val: string | number, format: string) =>
  `${Number(val) < 0 ? '-' : ''}$${numeral(val)
    .format(format)
    .replace('-', '')}`;
export const usMoneyFormat = (val: string | number) =>
  usMoneyBaseFormat(val, '0,0[.]00');
export const usMoneyFormatWithCents = (val: string | number) =>
  usMoneyBaseFormat(val, '0,0.00');

export const usMoneyInCents = (val: string | number) => {
  if (val == null) return val;
  const valString = val.toString();

  // EVEN DOLLAR AMOUNTS WON'T WORK WITH THE TOMONEYSANSSIGN FORMATTER
  if (valString.indexOf('.') === -1) {
    return parseInt(`${val}00`, 10);
  }

  // Fixes $42.5 situation not enough cents
  if (valString.match(/\.\d$/g)) {
    return parseInt(`${valString.replace('.', '')}0`, 10);
  }

  return parseInt(valString.replace('.', ''), 10);
};

export const monthDay = (val: string | number) =>
  `${moment(val).format('MMMM D')}`;
export const serverDate = (val: string | number) =>
  `${moment(val).format('YYYY-MM-DD')}`;

export function formatAddressShort(address: any) {
  return `${address.streetLine1} - ${address.state}`;
}

export function formatBytes(bytes: any) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';

  const BYTE_SIZE = 1000;
  const i = +Math.floor(Math.log(bytes) / Math.log(BYTE_SIZE));
  const strictSize = BYTE_SIZE ** i;

  return `${Math.round(bytes / strictSize)} ${sizes[i]}`;
}

export const formatPhoneNumber = (phoneNumberString: string) => {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return null;
};

export const formatPhoneNumberKebab = (phoneNumberString: string) => {
  if (!phoneNumberString) {
    return '';
  }

  const digits = phoneNumberString.replace(/\D*/g, '');
  const first = digits.substr(0, 3);
  const second = digits.substr(3, 3);
  const third = digits.substr(6, 4);
  const formatted = `${first}${digits.length > 3 ? '-' : ''}${second}${
    digits.length > 6 ? '-' : ''
  }${third}`;

  return formatted;
};

export const formatValidationCode = (code: string) => {
  // EXAMPLE 123-456

  // DON'T FORMAT THE CODE YET BECAUSE WE MIGHT WANT TO DELETE THE FIRST FEW CHARACTERS
  if (code.length < 4) {
    return code;
  }
  return code.replace(/(.{3})/, '$1-');
};
