export const RADIUS = 10;

export const START_HOMEPAGE_MAINTENANCE_PATH =
  'START_HOMEPAGE_MAINTENANCE_PATH';
export const START_HOMEPAGE_BOOKING_PATH = 'START_HOMEPAGE_BOOKING_PATH';
export const START_GARAGE_SCHEDULE_SERVICE = 'START_GARAGE_SCHEDULE_SERVICE';
export const START_RECOMMENDED_REPAIRS = 'START_RECOMMENDED_REPAIRS';
export const START_QUERYSTRING_FLOW = 'START_QUERYSTRING_FLOW';
export const START_APPT_TRACKER_ADD_SERVICE = 'START_APPT_TRACKER_ADD_SERVICE';
export const START_EDIT_QUOTE_ADD_SERVICE = 'START_EDIT_QUOTE_ADD_SERVICE';
export const START_PATH_AFTER_QUOTE_UPDATED = 'START_PATH_AFTER_QUOTE_UPDATED';
export const START_FLEET_SCHEDULE_PATH = 'START_FLEET_SCHEDULE_PATH';
