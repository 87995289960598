import { DiagnoseRepair } from 'entities/Diagnose';
import { PreliminaryKnowRepair } from 'entities/KnowRepair';

export const getAllRepairIds = (
  knownRepairs: PreliminaryKnowRepair[],
  diagnosesRepairs: DiagnoseRepair[]
) => {
  const repairIds: Set<number> = new Set();
  knownRepairs?.forEach((repair) => repairIds.add(repair.nodeId));
  diagnosesRepairs?.forEach((diagnosis) => {
    if (!diagnosis.repairs?.length) return;
    diagnosis.repairs.forEach((repair) => {
      repairIds.add(repair.repairId);
    });
  });

  const result: number[] = Array.from(repairIds).sort();
  return result;
};
