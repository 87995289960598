import { handleActions } from 'redux-actions';
import {
  getAppointments,
  getAppointmentsHistory,
  getAppointmentsHistoryV2,
  getCars,
  addNewCar,
  getAddresses,
  setDeliveryAddress,
  setConsumerSelectedAddress,
  createGroup,
  getGroups,
  updateGroup,
  updateGroups,
  getPaymentMethods,
  addVwoExperimentViewed,
  getVwoExperimentsViewed,
  getCredits,
  getCarsFiltered,
  getCarsAggregatedInfo,
  setGroups,
  getCar,
  setCar,
} from 'store/consumer/actions';
import { signOut } from 'store/auth/actions';
import createAPIHandlers from 'store/utils/createAPIHandler';
import { createAPIInfiniteScrollHandler } from 'store/utils/createAPIInfiniteScrollHandler';
import { createSimpleHandler } from 'store/utils/createSimpleHandler';
import { Car } from 'entities/Car';
import { Pagination } from 'entities/Pagination';
import { AppointmentForCards } from 'entities/AppointmentForCards';
import { CarsGroup } from 'entities/CarsGroup';
import { PaymentMethod } from 'entities/PaymentMethod';
import { VwoExperimentViewed } from 'entities/vwoExperimentViewed';
import { Address } from 'entities/Address';
import { Credit } from 'entities/Credit';
import { ICarFilterAggregateInfo } from 'entities/CarsFilter';

export interface ConsumerStoreState {
  appointments: {
    requesting: boolean;
    data: AppointmentForCards[];
    errors: any;
  };
  appointmentsHistory?: {
    requesting: boolean;
    data: AppointmentForCards[];
    errors: any;
    pagination: Pagination;
  };
  car?: Car;
  cars?: {
    requesting: boolean;
    data: { carDetails: Car[] };
    errors: any;
  };
  carsFiltered?: {
    requesting: boolean;
    data: any;
    errors: any;
  };
  carsAggregatedInfo?: {
    carYears: ICarFilterAggregateInfo[];
    makes: ICarFilterAggregateInfo[];
    models: ICarFilterAggregateInfo[];
    mileages: ICarFilterAggregateInfo[];
  };
  consumerSelectedAddress: boolean;
  groups: {
    requesting: boolean;
    data?: CarsGroup[];
    errors: any;
  };
  paymentMethods: PaymentMethod[];
  vwoExperimentsViewed: VwoExperimentViewed[];
  addresses?: Address[];
  credits?: Credit[];
  validateVinOrPlate?: boolean;
}

const defaultState: ConsumerStoreState = {
  appointments: {
    requesting: false,
    data: [],
    errors: null,
  },
  appointmentsHistory: undefined,
  groups: {
    requesting: false,
    data: undefined,
    errors: null,
  },
  car: undefined,
  cars: undefined,
  carsFiltered: undefined,
  carsAggregatedInfo: undefined,
  consumerSelectedAddress: false,
  paymentMethods: [],
  vwoExperimentsViewed: [],
  addresses: undefined,
  credits: undefined,
  validateVinOrPlate: undefined,
};

const reducer = handleActions<any>(
  {
    [getCar?.typeAPISuccess]: createSimpleHandler('car'),
    [setCar.type]: (state: ConsumerStoreState, action: any) => ({
      ...state,
      car: action.payload,
    }),
    ...createAPIHandlers(getCars, 'cars'),
    [getCarsFiltered.typeAPISuccess]: (
      state: ConsumerStoreState,
      action: {
        payload: {
          content: Car[];
          number: number;
          last: number;
          first: number;
          size: number;
        };
      }
    ) => {
      const carsData = state.carsFiltered?.data?.content ?? [];

      let newCarsData = action.payload.content;
      if (action.payload.number > 0) {
        newCarsData = [...carsData, ...action.payload.content];
      }

      return {
        ...state,
        carsFiltered: {
          ...state.carsFiltered,
          data: {
            ...action.payload,
            content: newCarsData,
            last: action.payload.last,
            first: action.payload.first,
            size: action.payload.size,
            number: action.payload.number,
          },
        },
      };
    },
    ...createAPIHandlers(getCarsAggregatedInfo, 'carsAggregatedInfo'),
    ...createAPIHandlers(getAppointments, 'appointments'),
    ...createAPIInfiniteScrollHandler(
      getAppointmentsHistory,
      'appointmentsHistory',
      '',
      true
    ),
    ...createAPIInfiniteScrollHandler(
      getAppointmentsHistoryV2,
      'appointmentsHistory',
      'content'
    ),
    ...createAPIHandlers(getGroups, 'groups'),
    [setGroups.type]: (state: ConsumerStoreState, action: any) => ({
      ...state,
      groups: {
        ...state.groups,
        data: action.payload,
      },
    }),
    [createGroup.typeAPISuccess]: (
      state: ConsumerStoreState,
      action: { payload: CarsGroup }
    ) => {
      return {
        ...state,
        groups: {
          ...state.groups,
          data: [action.payload, ...(state.groups.data ?? [])],
        },
      };
    },
    [updateGroup.typeAPISuccess]: (
      state: ConsumerStoreState,
      action: { payload: CarsGroup }
    ) => {
      return {
        ...state,
        groups: {
          ...state.groups,
          data: state.groups.data?.map((g) =>
            g.id !== action.payload.id ? g : action.payload
          ),
        },
      };
    },
    [updateGroups.typeAPISuccess]: (
      state: ConsumerStoreState,
      action: { payload: { groups: CarsGroup[] } }
    ) => {
      return {
        ...state,
        groups: {
          ...state.groups,
          data: action.payload.groups,
        },
      };
    },
    [addNewCar.typeAPISuccess]: (
      state: ConsumerStoreState,
      action: { payload: { carDetails: Car[] } }
    ) => {
      if (!state.cars) return state;
      return {
        ...state,
        cars: {
          ...state.cars,
          data: {
            ...state.cars.data,
            carDetails: [
              ...state.cars.data.carDetails,
              ...action.payload.carDetails,
            ],
          },
        },
      };
    },
    [getAddresses.typeAPISuccess]: createSimpleHandler('addresses'),
    [setDeliveryAddress.type]: createSimpleHandler('deliveryAddress'),
    [setConsumerSelectedAddress.type]: createSimpleHandler(
      'consumerSelectedAddress'
    ),
    [getPaymentMethods.typeAPISuccess]: createSimpleHandler('paymentMethods'),
    [getVwoExperimentsViewed.typeAPISuccess]: (
      state: ConsumerStoreState,
      action: { payload: any }
    ) => {
      return {
        ...state,
        vwoExperimentsViewed: JSON.parse(action.payload.vwoTestData ?? '[]'),
      };
    },
    [addVwoExperimentViewed.type]: (
      state: ConsumerStoreState,
      action: { payload: VwoExperimentViewed }
    ) => {
      return {
        ...state,
        vwoExperimentsViewed: [
          ...state.vwoExperimentsViewed,
          {
            ...action.payload,
            seenOn: new Date().toUTCString(),
          },
        ],
      };
    },
    [getCredits?.typeAPISuccess]: createSimpleHandler('credits'),
    [signOut?.type]: () => ({ ...defaultState }),
  },
  defaultState
);

export default reducer;
