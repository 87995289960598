import { createSelector } from 'reselect';
import { some, last, isNil, every } from 'lodash';
import { defaultCar } from 'store/preliminaryRepair/reducer';
import { selectSymptomTree } from 'store/repairs/selectors';
import {
  isUserLoggedIn,
  selectUserRegistrationData,
} from 'store/auth/selectors';
import {
  selectServicesList,
  selectAppointments,
} from 'store/consumer/selectors';
import { selectAppointment } from 'store/repairRequest/selectors';
import { RootStoreState } from 'store/reducers';
import { getAllRepairIds } from 'store/utils/getAllRepairIds';
import { updateDiagnoses } from 'utilities/updateDiagnoses';
import { Price } from 'entities/Price';
import { Diagnose, TraverseNode } from 'entities/Diagnose';
import {
  isKnowRepairWithPrice,
  KnowRepairWithPrice,
  PreliminaryKnowRepair,
} from 'entities/KnowRepair';
import { AppointmentForCards } from 'entities/AppointmentForCards';
import { AppointmentData } from 'entities/AppointmentData';
import { PreliminaryCar } from 'entities/PreliminaryCar';
import {
  CONSUMER_CONFIRMED,
  GENERATING_QUOTE,
  PENDING_CONFIRM,
  PENDING_SCHEDULE,
} from '../repairRequest/constants';

export const selectPreliminaryRepair = (state: RootStoreState) =>
  state.preliminaryRepair;

export const selectStartPath = createSelector(
  selectPreliminaryRepair,

  (repair) => repair.startPath
);

export const selectDeclineRepairs = createSelector(
  selectPreliminaryRepair,

  (repair) => repair.declineRepairs
);

export const selectCar = createSelector(
  selectPreliminaryRepair,
  (repair) => repair?.car ?? null
);

export const selectSelectorCar = createSelector(selectCar, (car) =>
  car && car.byLicensePlate
    ? (defaultCar as PreliminaryCar)
    : (car as PreliminaryCar)
);

export const selectCarImageUrl = createSelector(
  selectCar,
  (car) => car?.image?.data?.url || ''
);

export const selectEditingRepairRequestId = createSelector(
  selectAppointment,
  (appointmentData: AppointmentData) =>
    appointmentData?.repairRequest?.referenceNum ?? null
);

export const selectRepairDailyCount = createSelector(
  selectPreliminaryRepair,
  (request) => (request.repairDailyCount ? request.repairDailyCount.count : 0)
);

export const selectScheduledDateTime = createSelector(
  selectPreliminaryRepair,
  (request) => request.scheduledDateTime
);

export const selectDelivery = createSelector(
  selectPreliminaryRepair,
  (request) => request.delivery || {}
);

export const selectDeliveryAddress = createSelector(
  selectDelivery,
  (delivery) => delivery.address
);

export const selectArrivalInstructions = createSelector(
  selectDelivery,
  (delivery) => delivery.arrivalInstructions ?? ''
);

export const selectDeliveryAddressZip = createSelector(
  selectDeliveryAddress,
  (deliveryAddress) => deliveryAddress?.zip
);

export const selectDeliveryCoordinates = createSelector(
  selectDelivery,
  (delivery) => delivery.coordinates || []
);

export const selectDeliveryHoursToFirstAvailableAppointment = createSelector(
  selectPreliminaryRepair,
  (repair) => repair?.delivery?.hoursToFirstAvailableAppointment
);

export const selectShop = createSelector(
  selectPreliminaryRepair,
  (request) => request.shop
);

export const selectBusinessHours = createSelector(selectShop, (shop) => {
  return shop?.businessHours || [];
});

export const selectZip = createSelector(
  selectPreliminaryRepair,
  (request) => request.zip || null
);

export const selectVisit = createSelector(
  selectPreliminaryRepair,
  (request) => request.visit
);

export const selectVisitRefNum = createSelector(
  selectPreliminaryRepair,
  (request) => request.visit?.refNum
);

export const selectCoordinates = createSelector(
  selectPreliminaryRepair,
  (request) => request.coordinates
);

export const selectTimezone = createSelector(
  selectPreliminaryRepair,
  selectAppointment,
  (request, appointment) => {
    return (
      request.timezone ||
      request.delivery?.address?.timezone ||
      appointment?.repairRequest?.activeAppointment?.timezone ||
      appointment?.repairRequest?.address?.timezone ||
      null
    );
  }
);

export const selectServices = createSelector(
  selectPreliminaryRepair,
  (request) => request.services
);

export const selectTotalLaborDurationInHours = createSelector(
  selectServices,
  (services) => services?.totalLaborDuration
);

export const selectTotalCost = createSelector(
  selectServices,
  (services) => services?.totalCost
);

export const selectServicesCount = createSelector(
  selectServices,
  (services) => {
    return (
      (services?.knownRepairs?.length ?? 0) +
      (services?.customRequests?.length ?? 0) +
      (services?.diagnoses?.length ?? 0)
    );
  }
);

export const selectTotalLaborDurationInMinutes = createSelector(
  selectServices,
  (services) => ((services?.totalLaborDuration || 1) * 60).toFixed(0)
);

export const selectKnownRepairs = createSelector(
  selectServices,
  (services) => services?.knownRepairs
);

export const selectKnownRepairsPrices = createSelector(
  selectKnownRepairs,
  (knownRepairs) => {
    const prices: Price[] = [];

    knownRepairs.forEach((known) => {
      if (isKnowRepairWithPrice(known)) {
        known.price.forEach((price) => prices.push(price));
      }
    });

    return prices;
  }
);

export const selectDiagnoses = createSelector(
  selectServices,
  (services) => services?.diagnoses
);

/**
 * GETS SYMPTOM NAMES AND REPAIR NAMES FOR MULTIPLE DIAGNOSES USING DECISION TREE DATA
 * FOR USE BEFORE PRICING IS RECEIVED
 * @type {OutputSelector<unknown, {symptoms: *, repairs: *}, (res1: unknown, res2: *) => {symptoms: *, repairs: *}>}
 */
export const selectDiagnosesRepairs = createSelector(
  selectSymptomTree,
  selectDiagnoses,
  (tree, diagnoses) => {
    const updated = updateDiagnoses(tree, diagnoses);

    return updated ?? [];
  }
);

export const selectMarkedDiagnoses = createSelector(
  selectDiagnoses,
  (diagnoses) =>
    diagnoses.map((item) => ({
      ...item,
      isKnownRepair: false,
    }))
);

export const selectMarkedDiagnosesRepairs = createSelector(
  selectDiagnosesRepairs,
  (diagnosesRepairs) =>
    diagnosesRepairs.map((item) => ({
      ...item,
      isKnownRepair: false,
    }))
);

export const selectMarkedKnownRepairs = createSelector(
  selectKnownRepairs,
  (knownRepairs) =>
    knownRepairs.map((item) => ({
      ...item,
      isKnownRepair: true,
    }))
);

export const selectSortedCartServices = createSelector(
  selectMarkedDiagnoses,
  selectMarkedKnownRepairs,
  (markedDiagnoses, markedKnownRepairs) => {
    return [...markedDiagnoses, ...markedKnownRepairs].sort(
      (a, b) => (a as Diagnose).index - (b as Diagnose).index
    );
  }
);

export const selectSortedRepairs = createSelector(
  selectMarkedDiagnosesRepairs,
  selectMarkedKnownRepairs,
  (markedDiagnosesRepairs, markedKnownRepairs) => {
    return [...markedDiagnosesRepairs, ...markedKnownRepairs].sort(
      (a: any, b: any) => a.index - b.index
    );
  }
);

export const selectSymptomsAsString = createSelector(
  selectDiagnosesRepairs,
  (diagnoses) => {
    let tStr = '';
    diagnoses.forEach((repairs) => {
      repairs.symptoms.map((v) => {
        tStr += `${v.symptom}, `;
        return tStr;
      });
    });
    return tStr;
  }
);

export const selectCustomServiceRequests = createSelector(
  selectServices,
  (services) => services?.customRequests
);

/**
 * GETS ALL KNOWN REPAIRS AND POSSIBLE DIAGNOSES REPAIRS
 * @type {OutputSelector<unknown, string, (res1: {symptoms: *, repairs: *}, res2: *) => string>}
 */
export const selectAllRepairsAsString = createSelector(
  selectDiagnosesRepairs,
  selectKnownRepairs,
  selectCustomServiceRequests,
  (diagnoses, knownRepairs, customRequests) => {
    let allRepairs: string[] = [];
    diagnoses.forEach((diag) => {
      allRepairs.push(`Inspection: ${diag.symptoms[0].symptom}`);
    });
    knownRepairs?.forEach((v) => {
      allRepairs.push(v.name);
    });

    if (customRequests?.length) {
      allRepairs = [...allRepairs, ...customRequests];
    }

    return allRepairs.join(', ');
  }
);

export const selectHasAnyPrice = createSelector(
  selectDiagnoses,
  selectKnownRepairsPrices,
  (diagnosesRepairs, knownRepairsPrices) => {
    const totalCost =
      Number(some(knownRepairsPrices, (repair) => repair.totalCost)) +
      Number(some(diagnosesRepairs, (diagnose) => diagnose.inspectionFee));

    return Boolean(totalCost || false);
  }
);

export const selectAllHavePrices = createSelector(
  selectDiagnoses,
  selectKnownRepairs,
  selectCustomServiceRequests,
  (diagnoses = [], knownRepairs = [], customRequests = []) => {
    const allKnownRepairsPrices = knownRepairs.every((repair: any) => {
      const repairHasPrices =
        repair?.price?.every((price: any) => !isNil(price.totalCost)) ?? false;
      return repairHasPrices;
    });

    const allDiagnosisPrices = diagnoses.every((diagnosis: any) => {
      return !isNil(diagnosis.inspectionFee);
    });

    // if quote has any custom service then the quote definitely has not all prices
    // because of all custom prices get from negotiations with customer
    // after creating a repair request
    const hasCustomServices = !!customRequests.length;

    return allKnownRepairsPrices && allDiagnosisPrices && !hasCustomServices;
  }
);

export const selectLaborRateTotal = createSelector(
  selectKnownRepairsPrices,
  (knownRepairsPrices) => {
    const laborRate =
      knownRepairsPrices.length > 0
        ? parseFloat(String(knownRepairsPrices[0].laborRate))
        : null;

    return laborRate || 0;
  }
);

export const selectLaborTimeTotal = createSelector(
  selectKnownRepairsPrices,
  (knownRepairsPrices) => {
    const laborTime =
      knownRepairsPrices.length > 0
        ? knownRepairsPrices.reduce(
            (acc, curr) =>
              parseFloat(String(acc)) + parseFloat(String(curr.laborTime || 0)),
            0
          )
        : null;

    return laborTime || 0;
  }
);

/**
 * GET ALL REPAIR IDS USING SERVICES DATA
 * @type {OutputSelector<unknown, Array, (res1: *, res2: unknown) => Array>}
 */
export const selectAllRepairsIds = createSelector(
  selectKnownRepairs,
  selectDiagnosesRepairs,
  getAllRepairIds
);

export const selectHasRepairs = createSelector(
  selectKnownRepairs,
  selectDiagnoses,
  selectCustomServiceRequests,
  (repairs, diagnosesRepairs, customRequests) => {
    return !!(
      repairs.length ||
      diagnosesRepairs.length ||
      customRequests.length
    );
  }
);

export const selectRepairAnalyticsCount = createSelector(
  selectKnownRepairs,
  selectDiagnoses,
  selectCustomServiceRequests,
  (knownRepairs, diagnoses, customRequest) => {
    return (
      (knownRepairs?.length ?? 0) +
      (diagnoses?.length ?? 0) +
      (customRequest?.length ?? 0)
    );
  }
);

export const selectRequestedServiceTypes = createSelector(
  selectKnownRepairs,
  selectDiagnoses,
  selectCustomServiceRequests,
  (knownRepairs, diagnosesRepairs, customRequests) => {
    const serviceTypes = [];

    if (knownRepairs?.length) {
      serviceTypes.push('Known Repair');
    }
    if (diagnosesRepairs?.length) {
      serviceTypes.push('Diagnosis');
    }
    if (customRequests?.length) {
      serviceTypes.push('Custom Service Request');
    }

    return serviceTypes.join(', ');
  }
);

export const selectRequestMetadata = createSelector(
  selectPreliminaryRepair,
  (repair) => repair.requestMetadata
);

export const selectQuoteId = createSelector(
  selectRequestMetadata,
  (requestMetadata) => requestMetadata?.quoteId
);

export const selectRepairRequestDataForSubmit = createSelector(
  selectZip,
  selectTimezone,
  selectDeliveryAddress,
  isUserLoggedIn,
  selectShop,
  selectCar,
  selectUserRegistrationData,
  selectAllRepairsAsString,
  selectSymptomsAsString,
  selectServices,
  selectRequestedServiceTypes,
  selectQuoteId,
  (
    zip,
    timezone,
    address,
    userLoggedIn,
    selectedShop,
    selectedCar,
    userRegistrationData,
    repairsStr,
    symptomsStr,
    services,
    requestedServiceTypes,
    quoteId
  ) => ({
    zip,
    timezone,
    address,
    userLoggedIn,
    selectedShop,
    selectedCar,
    userRegistrationData,
    repairsStr,
    symptomsStr,
    services,
    requestedServiceTypes,
    quoteId,
  })
);

export const selectHasService = createSelector(
  selectServices,
  ({ customRequests, knownRepairs, diagnoses }) => {
    return Boolean(
      knownRepairs.length || diagnoses.length || customRequests.length
    );
  }
);

export const selectServicesNames = createSelector(
  selectServices,
  ({ customRequests, knownRepairs, diagnoses }) => {
    return selectServicesList(
      customRequests.map((request) => ({ message: request })),
      diagnoses,
      knownRepairs
    );
  }
);

export const selectSymptomsIds = createSelector(selectDiagnoses, (diagnoses) =>
  diagnoses.map((o: any) => (last(o.traverseNodes) as TraverseNode).nodeId)
);

export const selectAsLowAsRepairsAnalytics = createSelector(
  selectKnownRepairs,
  (knownRepairs) => {
    const outOfRangeRepairs: string[] = [];
    const quotedRepairs: string[] = [];

    knownRepairs.forEach((repair: any) => {
      const price = repair.price ? repair.price[0] : null;

      const showAsLowAsDisplay = isNil(price)
        ? false
        : price.rangeExceedsThreshold &&
          price.totalCost &&
          price.totalCost === price.calculatedMinTotalCost;

      if (showAsLowAsDisplay) {
        outOfRangeRepairs.push(repair.name);
      } else {
        quotedRepairs.push(repair.name);
      }
    });

    return {
      'Count As low as': outOfRangeRepairs.length,
      'Count Quoted Repairs': quotedRepairs.length,
      'As low as Repairs': outOfRangeRepairs.join(', '),
      'Quoted Repairs': quotedRepairs.join(', '),
    };
  }
);

export const findActiveAppointmentForCar = (
  appointments: AppointmentForCards[],
  carId: number
) => {
  return appointments.find((appointment) => {
    const neededStatus = [
      GENERATING_QUOTE,
      PENDING_SCHEDULE,
      PENDING_CONFIRM,
      CONSUMER_CONFIRMED,
    ].includes(appointment.trackerState);

    const neededCar = appointment.car.consumerCarId === carId;

    return neededStatus && neededCar;
  });
};

export const selectFirstActiveAppointmentForCar = createSelector(
  selectAppointments,
  selectCar,
  (appointments: { data: AppointmentForCards[] }, car) => {
    const appointmentsData = appointments.data;

    if (appointmentsData && car && car.carId && car.carId.value) {
      return findActiveAppointmentForCar(appointmentsData, car.carId.value);
    }

    return null;
  }
);

export const selectNote = createSelector(
  selectPreliminaryRepair,
  (repair) => repair.note
);

export const selectSaveBookingData = createSelector(
  selectPreliminaryRepair,
  (repair) => repair.savedBookingData
);

export const selectCarError = createSelector(selectCar, (car) => car?.error);

export const selectIsLaborOnlyRequestQuote = createSelector(
  selectServices,
  (diagnosisServices) => {
    if (!diagnosisServices) return false;

    const noCustomRepairs = !diagnosisServices.customRequests?.length;
    const hasDiagnosisServices = !!diagnosisServices.diagnoses?.length;
    const noKnownRepairs = !diagnosisServices.knownRepairs?.length;

    const allKnownRepairAreLaborOnly = every(
      (diagnosisServices.knownRepairs as any[]) || [],
      ({ price: prices }) => {
        return every(prices || [], (price: Price) => {
          const notConcierge = !price.concierge;
          const noFluids = !price.fluids?.length;
          const noParts = !price.parts?.length;
          const hasLaborCost = price.laborCost && price.laborCost > 0;
          return notConcierge && noFluids && noParts && hasLaborCost;
        });
      }
    );

    return (
      noCustomRepairs &&
      ((hasDiagnosisServices && noKnownRepairs) || allKnownRepairAreLaborOnly)
    );
  }
);

export const selectNonLaborOnlyRepairIdsQuote = createSelector(
  selectServices,
  (diagnosisServices) => {
    if (!diagnosisServices || diagnosisServices.customRequests?.length)
      return [];

    const knownRepairs = diagnosisServices.knownRepairs || [];
    return knownRepairs
      .filter((knownRepair: any) => {
        return some(knownRepair.price || [], (price: Price) => {
          const hasFluids = price.fluids?.length;
          const hasParts = price.parts?.length;
          return hasFluids || hasParts;
        });
      })
      .map(
        (knownRepair: KnowRepairWithPrice | PreliminaryKnowRepair) =>
          knownRepair.nodeId
      );
  }
);

export const selectRepairRequestError = createSelector(
  selectPreliminaryRepair,
  (repair) => repair.repairRequestError
);

export const selectThrownAutoBookShownEvent = createSelector(
  selectPreliminaryRepair,
  (repair) => repair.thrownAutoBookShownEvent
);

export const selectAddressIsCustom = createSelector(
  selectDelivery,
  (delivery) => delivery.addressIsCustom ?? false
);

export const selectRequestCredit = createSelector(
  selectPreliminaryRepair,
  (repair) => repair.credit
);

export const selectAppointmentPreference = createSelector(
  selectPreliminaryRepair,
  (repair) => repair.appointmentPreference
);

export const selectBillingAddress = createSelector(
  selectPreliminaryRepair,
  (repair) => repair.billingAddress
);
