import { set, get } from 'lodash';
import { APIAction } from './createAPIAction';
import { AnyStoreState } from '../reducers';

const createAPIHandlers = (
  action: APIAction<any, any, any, any>,
  basePath: string,
  pathFromPayload?: string
) => ({
  [action?.typeAPIRequest]: <T extends AnyStoreState>(state: T) =>
    set<T>({ ...state }, basePath, { requesting: true }),
  [action?.typeAPISuccess]: <T extends AnyStoreState>(
    state: T,
    { payload }: any
  ) =>
    set<T>({ ...state }, basePath, {
      data: pathFromPayload ? get(payload, pathFromPayload, null) : payload,
      requesting: false,
      errors: [],
    }),
  [action?.typeAPIFail]: <T extends AnyStoreState>(
    state: T,
    { payload }: any
  ) =>
    set<T>({ ...state }, basePath, {
      data: null,
      requesting: false,
      errors: payload,
    }),
});

export default createAPIHandlers;
