import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery, useTheme, Snackbar } from '@mui/material';

import {
  selectSnackbarMessage,
  selectSnackbarMeta,
} from 'store/global/selectors';
import { clearSnackbarMessage } from 'store/global/actions';

import './GlobalSnackbar.scss';

const AUTO_HIDE_TIME_MS = 3000;

const Snack = () => {
  const dispatch = useDispatch();

  const message = useSelector(selectSnackbarMessage);
  const meta = useSelector(selectSnackbarMeta);

  const onClose = () => {
    dispatch(clearSnackbarMessage());
  };

  const theme = useTheme();
  const isTabletAndDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const vertical = isTabletAndDesktop ? 'top' : 'bottom';
  const defaultAnchorOrigin = { vertical, horizontal: 'center' };

  return (
    <Snackbar
      className="GlobalSnackbar"
      autoHideDuration={AUTO_HIDE_TIME_MS}
      onClose={onClose}
      open={!!message}
      message={message}
      anchorOrigin={{
        ...defaultAnchorOrigin,
        ...(meta?.anchorOrigin ?? {}),
      }}
    />
  );
};

export default Snack;
