import { createAction } from 'store/utils/createAction';
import { createAPIAction } from 'store/utils/createAPIAction';
import queryString from 'query-string';
import { User } from 'entities/User';

const serviceName = 'auth';

export const loginUser = createAPIAction(
  `${serviceName}/LOGIN_USER`,
  (data: { password: string; email: string }) => ({
    endpoint: '/consumer-service/login',
    method: 'POST',
    data,
  })
);

export const registerUser = createAPIAction(
  `${serviceName}/REGISTER_USER`,
  (data: any) => ({
    endpoint: '/consumer-service/consumer/profile',
    method: 'POST',
    data,
  })
);

export const updateUser = createAPIAction(
  `${serviceName}/UPDATE_USER`,
  (data: any) => ({
    endpoint: '/consumer-service/consumer',
    method: 'PUT',
    data,
  })
);

export const getUser = createAPIAction(`${serviceName}/GET_USER`, () => ({
  endpoint: '/consumer-service/consumer',
}));

interface ValidateEmailProps {
  email: string;
  firstName: string;
  lastName: string;
}

export const validateEmail = createAPIAction(
  `${serviceName}/VALIDATE_EMAIL`,
  (consumer: ValidateEmailProps) => {
    const { email, firstName, lastName } = consumer;
    const params = queryString.stringify({
      email,
      firstName,
      lastName,
    });

    return {
      endpoint: `/consumer-service/consumer/validate-consumer-details?${params}`,
    };
  }
);

export const setUserRegistrationData = createAction(
  `${serviceName}/SET_USER_REGISTRATION_DATA`
);

export const setTokensFromCookie = createAction(
  `${serviceName}/SET_TOKENS_FROM_COOKIE`
);

export const signOut = createAction(`${serviceName}/LOGOUT_USER`);

export const sendResetPasswordEmail = createAPIAction(
  `${serviceName}/SEND_RESET_PASSWORD_EMAIL`,
  (data: any) => ({
    endpoint: '/consumer-service/password/reset',
    method: 'POST',
    data,
  })
);

export const resetPassword = createAPIAction<
  [
    {
      password: string;
    }
  ]
>(`${serviceName}/RESET_PASSWORD`, (data) => ({
  endpoint: '/consumer-service/password/reset',
  method: 'PUT',
  data,
}));

export const setPhoneVerified = createAction(
  `${serviceName}/SET_PHONE_VERIFIED`
);

interface UpdateRequestContactInfoResponse {
  consumerId: number;
  created: string;
  createdBy: string;
  email: string;
  firstName: string;
  lastName: string;
  modified: string;
  modifiedBy: string;
  name: string;
  phone: string;
  phoneNumberValidated: boolean;
  skeduloContactId: string;
}

export const updateConsumerAndRequest = createAPIAction<
  [{ user: Partial<User>; referenceNum: string }],
  any,
  UpdateRequestContactInfoResponse
>(`${serviceName}/UPDATE_CONSUMER_AND_REQUEST`, ({ referenceNum, user }) => ({
  endpoint: `/consumer-service/consumer?referenceNum=${referenceNum}`,
  method: 'PUT',
  data: user,
}));
