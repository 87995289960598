import { createSelector } from 'reselect';
import { GlobalStoreState } from './reducer';

export const selectGlobal = (state: GlobalStoreState) =>  (state?.global);

export const selectSnackbarMessage = createSelector(
  selectGlobal,
  (global) => global?.snackbarMessage
);

export const selectSnackbarMeta = createSelector(
  selectGlobal,
  (global) => global?.snackbarMeta
);

export const selectSharedAnalytics = createSelector(
  selectGlobal,
  (global) => global?.sharedAnalytics
);
