import { handleActions } from 'redux-actions';
import { createSimpleHandler } from 'store/utils/createSimpleHandler';
import {
  getYears,
  getMakes,
  getModels,
  getCarImage,
} from 'store/autoSelection/actions';

export interface Make {
  value: string;
  id?: number;
}

export interface AutoSelectionStoreState {
  years?: number[];
  makes?: Make[];
  models?: Record<string, any>[];
  carImage?: Record<string, any>;
}

const defaultState: AutoSelectionStoreState = {
  years: [],
  makes: [],
  models: [],
  carImage: undefined,
};

const reducer = handleActions(
  {
    [getYears.typeAPISuccess]: createSimpleHandler('years'),
    [getMakes.typeAPISuccess]: createSimpleHandler('makes'),
    [getModels.typeAPISuccess]: createSimpleHandler('models'),
    [getCarImage.typeAPISuccess]: createSimpleHandler('carImage'),
  },
  defaultState
);

export default reducer;
