import { set, get } from 'lodash';
import { AnyStoreState } from '../reducers';

/**
 *  Creates a function to update a single field in a reducer
 */
export const createSimpleHandler =
  <T>(fieldName: string, source?: string, debugMessage?: string) =>
  (state: AnyStoreState, { payload }: { payload: any }): T => {
    const data = source ? get(payload, source, '') : payload;

    if (debugMessage) {
      console.info(debugMessage); // eslint-disable-line no-console
    }

    return set<T>({ ...state }, fieldName, data);
  };
