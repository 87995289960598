import { handleActions } from 'redux-actions';
import { createSimpleHandler } from 'store/utils/createSimpleHandler';
import { createClearHandler } from 'store/utils/createClearHandler';
import {
  clearPromoCode,
  applyPromoCode,
  setPromoCode,
  removePromoCode,
  getPromoData,
  getCancellationPromo,
  clearCancellationDiscount,
} from 'store/promo/actions';

import { signOut } from 'store/auth/actions';
import { usMoneyFormatWithCents } from '../../utilities/formatting';

export interface PromoStoreState {
  validation: any;
  applyRepairRequestPromo: any;
  repairRequestPromo: any;
  invoiceDiscount: any;
  cancellationDiscount: any;
}

const defaultState: PromoStoreState = {
  validation: {
    code: '',
    fetching: false,
    valid: false,
    invalid: false,
    discountAmount: null,
    validMessage: '',
    invalidMessage: 'Enter a valid discount code',
  },
  applyRepairRequestPromo: {},
  repairRequestPromo: {},
  invoiceDiscount: {},
  cancellationDiscount: {},
};

const hydrateValidation = (state: any, action: any) => {
  const validation = {
    ...state.validation,
  };
  const data = action.payload.length ? action.payload[0] : null;
  const hasActionData = data;
  if (hasActionData) {
    const promoCodeData = data?.promoCode ?? {};
    const { discountType, amountOff, percentOff } = promoCodeData;

    let validMessage = '';
    if (discountType === 'AMOUNT') {
      validMessage = `${usMoneyFormatWithCents(amountOff)} Off`;
    } else if (discountType === 'PERCENT') {
      validMessage = `${percentOff}% Off`;
    }

    return {
      ...state.validation,
      code: data?.promoCode?.code,
      discountAmount: data?.discountedAmount,
      fetching: false,
      valid: true,
      invalid: false,
      validMessage,
      invalidMessage: '',
    };
  }

  return validation;
};

const reducer = handleActions<PromoStoreState>(
  {
    [clearPromoCode?.type]: createClearHandler('promo', { ...defaultState }),
    [applyPromoCode?.typeAPISuccess]: (state: any, action: any) => {
      return {
        ...state,
        validation: hydrateValidation(state, action),
        repairRequestPromo: [action.payload],
        applyRepairRequestPromo: action.payload,
      };
    },
    [signOut?.type]: () => ({ ...defaultState }),
    [setPromoCode?.type]: createSimpleHandler('validation'),
    [getCancellationPromo?.typeAPISuccess]: createSimpleHandler(
      'cancellationDiscount'
    ),
    [clearCancellationDiscount?.type]: createClearHandler(
      'cancellationDiscount',
      undefined
    ),
    [removePromoCode?.typeAPISuccess]: () => ({ ...defaultState }),
    [getPromoData?.typeAPISuccess]: (state: any, action: any) => {
      return {
        ...state,
        validation: hydrateValidation(state, action),
        repairRequestPromo: action.payload,
      };
    },
  },

  defaultState
);

export default reducer;
