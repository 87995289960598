import HeadsetMicRoundedIcon from '@mui/icons-material/HeadsetMicRounded';
import { Fab } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/auth/selectors';
import { useState } from 'react';
import { setSnackbarMessage } from 'store/global/actions';

import './FleetContactSupport.scss';

const FleetContactSupport = () => {
  const [isChatting, setIsChatting] = useState<boolean>(false);
  const user = useSelector(selectUser);

  if (typeof window.zE === 'function') {
    try {
      window.zE('webWidget:on', 'chat:start', () => {
        setIsChatting(true);
      });
      window.zE('webWidget:on', 'chat:end', () => {
        setIsChatting(false);
      });
    } catch (error) {
      setSnackbarMessage(error); // eslint-disable-line no-alert
    }
  }

  const openChat = () => {
    if (user?.email && window.$zopim) {
      window.$zopim(() => {
        window.$zopim.livechat.setName(user.fleetProfile?.fleetName);
        window.$zopim.livechat.setEmail(user.email);
        window.$zopim.livechat.departments.setVisitorDepartment(16627990743835);

        window.zE?.activate();
      });
    }
  };

  return !user?.fleet || isChatting ? null : (
    <div className="FleetContactSupport">
      <Fab
        color="primary"
        aria-label="Contact Support"
        title="Contact Support"
        variant="circular"
        size="large"
        onClick={openChat}
      >
        <HeadsetMicRoundedIcon />
      </Fab>
    </div>
  );
};

export default FleetContactSupport;
