import Geocode from 'react-geocode';
import { GeoData } from '../entities/Geodata';
import { Coordinate } from '../store/shops/types';

const { GOOGLE_PLACES_API_KEY } = window.RepairSmith;

let instance: any = null;

export const createInstance = () => {
  Geocode.setApiKey(GOOGLE_PLACES_API_KEY);
  Geocode.setLanguage('en');
  Geocode.setRegion('us');

  return Geocode;
};

export const GMapClient = () => {
  if (!instance) {
    instance = createInstance();
  }
  return instance;
};

export const getCoordinates = (geoData: GeoData): [number, number] => {
  const { lng = 0, lat = 0 } = geoData?.results[0]?.geometry?.location ?? {};
  return [lng, lat] as Coordinate;
};
