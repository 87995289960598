import { createAction } from 'store/utils/createAction';
import { createAPIAction } from 'store/utils/createAPIAction';
import {
  findLatestOneByConsumerId,
  findInspectionByRepairRequestId,
  findInspectionsByConsumerId,
  findInspection,
  getAllByConsumerId,
  findLatestByRepairRefNum,
  findLatestByConsumerId,
} from './queries';

import {
  FindLatestByConsumerIdVariables,
  GetAllByConsumerIdVariables,
} from './types';

const serviceName = 'inspections';
const graphqlEndpoint = '/mpi-service/graphql';

export const getLastUserInspection = createAPIAction(
  `${serviceName}/GET_LAST_USER_INSPECTION_WITHIN`,
  (data: FindLatestByConsumerIdVariables) => ({
    endpoint: graphqlEndpoint,
    method: 'POST',
    data: findLatestOneByConsumerId(data),
  })
);

export const getLastUserInspections = createAPIAction(
  `${serviceName}/GET_LAST_USER_INSPECTIONS_WITHIN`,
  (data: FindLatestByConsumerIdVariables) => ({
    endpoint: graphqlEndpoint,
    method: 'POST',
    data: findLatestByConsumerId(data),
  })
);

export const getUserInspections = createAPIAction(
  `${serviceName}/GET_USER_INSPECTIONS`,
  (data: GetAllByConsumerIdVariables) => ({
    endpoint: graphqlEndpoint,
    method: 'POST',
    data: getAllByConsumerId(data),
  })
);

export const getInspectionByRepairRequestId = createAPIAction(
  `${serviceName}/GET_INSPECTION_BY_REPAIR_REQUEST_ID`,
  (data: { repairRequestId: string }) => ({
    endpoint: '/mpi-service/graphql',
    method: 'POST',
    data: findInspectionByRepairRequestId(data),
  })
);

export const getLatestInspectionByRepairRefNum = createAPIAction(
  `${serviceName}/GET_INSPECTION_BY_REPAIR_REF_NUM`,
  (data: { repairRefNum: string }) => ({
    endpoint: '/mpi-service/graphql',
    method: 'POST',
    data: findLatestByRepairRefNum(data),
  })
);

export const getHealthInspectionHistory = createAPIAction(
  `${serviceName}/GET_HEALTH_INSPECTION_HISTORY`,
  (data: { consumerId: number }) => ({
    endpoint: '/mpi-service/graphql',
    method: 'POST',
    data: findInspectionsByConsumerId(data),
  })
);

export const getInspection = createAPIAction(
  `${serviceName}/GET_HEALTH_INSPECTION`,
  (data: { inspectionId: string }) => ({
    endpoint: '/mpi-service/graphql',
    method: 'POST',
    data: findInspection(data),
  })
);

export const updateInspectionRepairs = createAction(
  `${serviceName}/UPDATE_INSPECTION_REPAIRS`
);

export const createQuoteNeededZendeskTicket = createAPIAction<
  [{ refNum: string }],
  any,
  any
>(`${serviceName}/CREATE_PENDING_ZENDESK_TICKET`, ({ refNum }) => ({
  endpoint: '/repair-service/zendesk/tickets',
  method: 'POST',
  data: {
    repairRequestReferenceNum: refNum,
    ticketType: 'MPI_QUOTE_NEEDED',
  },
}));

export const getQuoteNeededZendeskTicket = createAPIAction<
  [{ refNum: string }],
  any,
  any
>(`${serviceName}/CREATE_PENDING_ZENDESK_TICKET`, ({ refNum }) => ({
  endpoint: `/repair-service/zendesk/tickets?referenceNumber=${refNum}&taskType=MPI_QUOTE_NEEDED`,
  method: 'GET',
}));

export const getReport = createAPIAction<[{ refNum: string }], any, any>(
  `${serviceName}/GET_REPORT`,
  ({ refNum }) => ({
    endpoint: `/repair-service/consumer/mpi/repair-requests/${refNum}`,
    method: 'GET',
  })
);
