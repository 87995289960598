import moment from 'moment';
import { createSelector } from 'reselect';
import { every, get, isEmpty, isNil, some, uniqBy } from 'lodash';
import { isUserLoggedIn } from 'store/auth/selectors';
import { AppointmentData } from 'entities/AppointmentData';
import { Car } from 'entities/Car';
import { KnowRepair } from 'entities/KnowRepair';
import { Price } from 'entities/Price';
import { getAllRepairIds } from 'store/utils/getAllRepairIds';
import { FunnelPage } from 'entities/Funnel';
import { PENDING_SCHEDULE } from './constants';

const getRepairRequest = (state: any) => state?.repairRequest ?? {};

export const selectRepairRequestShared = createSelector(
  getRepairRequest,
  (repair) => get(repair, 'repairRequestShared')
);

export const selectAppointment = createSelector(
  getRepairRequest,
  (repair) => repair?.appointment
);

export const getResolutions = createSelector(
  getRepairRequest,
  (repair) => repair?.resolutions
);

export const selectIsAutoBook = createSelector(
  selectAppointment,
  (appointmentData) => appointmentData?.autoBook
);

export const selectIsLaborOnlyRequest = createSelector(
  selectAppointment,
  (appointmentData) => {
    const { diagnosisServices } = appointmentData?.diagnosis || {};
    if (!diagnosisServices) return false;

    const noCustomRepairs = !diagnosisServices.customRequests?.length;
    const hasDiagnosisServices = !!diagnosisServices.diagnoses?.length;
    const noKnownRepairs = !diagnosisServices.knownRepairs?.length;
    const allKnownRepairAreLaborOnly = every(
      diagnosisServices.knownRepairs || [],
      ({ price: prices }) => {
        return every(prices || [], (price: Price) => {
          const notConcierge = !price.concierge;
          const noFluids = !price.fluids?.length;
          const noParts = !price.parts?.length;
          const hasLaborCost = price.laborCost && price.laborCost > 0;
          return notConcierge && noFluids && noParts && hasLaborCost;
        });
      }
    );

    return (
      noCustomRepairs &&
      ((hasDiagnosisServices && noKnownRepairs) || allKnownRepairAreLaborOnly)
    );
  }
);

export const selectNonLaborOnlyRepairIds = createSelector(
  selectAppointment,
  (appointmentData) => {
    const { diagnosisServices } = appointmentData?.diagnosis || {};
    if (!diagnosisServices || diagnosisServices.customRequests?.length)
      return [];

    const knownRepairs = diagnosisServices.knownRepairs || [];
    return knownRepairs
      .filter((knownRepair: KnowRepair) => {
        return some(knownRepair.price || [], (price: Price) => {
          const hasFluids = price.fluids?.length;
          const hasParts = price.parts?.length;
          return hasFluids || hasParts;
        });
      })
      .map((knownRepair: KnowRepair) => knownRepair.nodeId);
  }
);

export const selectTransactions = createSelector(
  selectAppointment,
  (appointmentData: any) => {
    return get(appointmentData, 'transactions', []).filter(
      (t: any) => !t.removed && t.status !== 'FAILED' && t.status !== 'DECLINED'
    );
  }
);

export const selectRepairRequest = createSelector(
  selectAppointment,
  (appointment: any) => get(appointment, 'repairRequest')
);

export const selectRepairRequestOrShared = createSelector(
  selectAppointment,
  selectRepairRequestShared,
  (appointment: any, shared: any) =>
    appointment?.repairRequest ?? shared?.repairRequest
);

export const selectRepairRequestAddress = createSelector(
  selectRepairRequest,
  (repairRequest) => repairRequest?.address
);

export const selectInvoiceDiscountDetails = createSelector(
  selectAppointment,
  selectRepairRequestShared,
  (appointmentData, repairRequestShared) => {
    const discountDetails =
      appointmentData?.repairRequest?.workOrder?.discountDetails ??
      repairRequestShared?.repairRequest?.workOrder?.discountDetails ??
      [];

    return discountDetails.filter(
      (discountDetail: any) =>
        discountDetail.workOrderStage === 'INVOICE' &&
        (discountDetail.discountedAmount ||
          discountDetail.discountType !== 'SUBSCRIPTION')
    );
  }
);

export const selectDiscountsTotal = createSelector(
  selectAppointment,
  selectRepairRequestShared,
  (appointmentData, sharedRequest) => {
    return (
      appointmentData?.repairRequest?.workOrder?.totalDiscount ??
      sharedRequest?.repairRequest?.workOrder?.totalDiscount
    );
  }
);

export const selectQuoteDiscountDetails = createSelector(
  selectAppointment,
  selectRepairRequestShared,
  (appointmentData, sharedRequest) => {
    const discountDetails =
      appointmentData?.repairRequest?.workOrder?.discountDetails ??
      sharedRequest?.repairRequest?.workOrder?.discountDetails ??
      [];

    return discountDetails.filter(
      (discountDetail: any) =>
        discountDetail.workOrderStage === 'QUOTE' &&
        (discountDetail.discountedAmount ||
          discountDetail.discountType !== 'SUBSCRIPTION')
    );
  }
);

export const selectRepairRequestCarVin = createSelector(
  selectAppointment,
  (appointment: any) => get(appointment, 'car.vin')
);

export const selectInvoice = createSelector(
  selectAppointment,
  (appointment) => appointment?.invoice ?? {}
);

export const selectShowAnonymousBooking = createSelector(
  selectRepairRequestShared,
  (repairRequestShared) => {
    return Boolean(
      repairRequestShared &&
        repairRequestShared.trackerState === PENDING_SCHEDULE &&
        (repairRequestShared.firstNameMissing ||
          repairRequestShared.lastNameMissing ||
          repairRequestShared.phoneNumberMissing)
    );
  }
);

export const selectRepairRequestWorkOrderId = createSelector(
  selectRepairRequest,
  (request) => request?.workOrder?.id
);

/*
  RESCHEDULE AND CANCEL APPOINTMENT
 */

export const selectRescheduleAppointmentRequestStatus = createSelector(
  getRepairRequest,
  (repair) => get(repair, 'rescheduleAppointmentRequestStatus')
);

export const selectRescheduleAppointmentErrors = createSelector(
  selectRescheduleAppointmentRequestStatus,
  (status) => get(status, 'errors', [])
);

export const selectCancelAppointment = createSelector(
  getRepairRequest,
  (repair) => get(repair, 'cancelAppointment', {})
);

export const selectUploadInvoiceDocumentRequesting = createSelector(
  getRepairRequest,
  (repair) => get(repair, 'invoice.requesting', false)
);

export const selectConfirmedAppointment = createSelector(
  getRepairRequest,
  (repair) => get(repair, 'confirmedAppointment', null)
);

export const selectConfirmedAppointmentRequesting = createSelector(
  selectConfirmedAppointment,
  (confirmedAppointment) => get(confirmedAppointment, 'requesting', false)
);

export const selectRepairRequestData = createSelector(
  selectRepairRequest,
  (repair) => {
    if (!repair) {
      return null;
    }

    return {
      ...repair,
      consumerPreferredTimes: repair.consumerPreferredTimes.map((x: any) => {
        return moment(
          `${x.appointmentDate} ${x.appointmentTime}`,
          'YYYY-MM-DD HH:mm:ss'
        );
      }),
    };
  }
);

export const selectAppointmentStep = createSelector(
  isUserLoggedIn,
  selectAppointment,
  selectRepairRequestShared,
  (userLoggedIn, appointmentData: AppointmentData, repairRequestSharedData) => {
    if (userLoggedIn) {
      return appointmentData?.trackerState;
    }

    if (isEmpty(repairRequestSharedData)) {
      return '';
    }

    return get(repairRequestSharedData, 'trackerState');
  }
);

export const selectDeliveryChannel = createSelector(
  selectRepairRequest,
  (repairRequest) => repairRequest?.activeAppointment?.deliveryChannel
);

export const selectDeliveryChannelShared = createSelector(
  selectRepairRequestShared,
  (repairRequest) =>
    repairRequest?.repairRequest?.activeAppointment?.deliveryChannel
);

export const selectShopBusinessHours = createSelector(
  selectAppointment,
  (appointment) => {
    const hours = get(
      appointment,
      'repairRequest.activeAppointment.ispContactInfo.businessHours',
      []
    );
    return hours;
  }
);

export const selectAllRepairsIdsFromRepairRequest = createSelector(
  selectRepairRequestShared,
  selectAppointment,
  (sharedAppointment, privateAppointment) => {
    if (
      !privateAppointment?.diagnosis?.diagnosisServices &&
      !sharedAppointment?.diagnosis?.diagnosisServices
    ) {
      return [];
    }
    const { knownRepairs, diagnosesRepairs } =
      privateAppointment?.diagnosis?.diagnosisServices ||
      sharedAppointment?.diagnosis?.diagnosisServices ||
      [];

    const knownIds: any = knownRepairs
      ? knownRepairs.map((repair: any) => repair.nodeId)
      : [];

    let diagnosesRepairsIds: any = [];
    if (diagnosesRepairs) {
      diagnosesRepairs.forEach((diagnosis: any) => {
        diagnosesRepairsIds = [
          ...diagnosesRepairsIds,
          ...diagnosis.repairs.map((repair: any) => repair.repairId),
        ];
      });
    }

    return uniqBy([...knownIds, ...diagnosesRepairsIds], 'id');
  }
);

export const selectSubmitAllRepairsRequesting = createSelector(
  getRepairRequest,
  (repairRequest) => {
    return get(repairRequest, 'submitAllRepairs.requesting', false);
  }
);

/**
 * GET ALL REPAIR IDS USING SERVICES DATA
 * @type {OutputSelector<unknown, Array, (res1: *, res2: unknown) => Array>}
 */
export const selectAllRepairsIds = createSelector(
  selectRepairRequestShared,
  selectAppointment,
  (sharedAppointment, privateAppointment) => {
    const { knownRepairs = [], diagnosesRepairs = [] } =
      privateAppointment?.diagnosis?.diagnosisServices ||
      sharedAppointment?.diagnosis?.diagnosisServices ||
      {};
    return getAllRepairIds(knownRepairs, diagnosesRepairs);
  }
);

export const selectInvoiceId = createSelector(
  getRepairRequest,
  (repairRequest) => repairRequest?.appointment?.invoice?.id
);

export const selectAllPricesWithDueDate = createSelector(
  selectAppointment,
  (appointmentData) => {
    const { diagnosisServices } = appointmentData?.diagnosis || {};
    if (!diagnosisServices) return true;

    return every(diagnosisServices.knownRepairs || [], ({ price }) => {
      return every(price || [], (p: Price) => !!p.dueDate);
    });
  }
);

export const selectServicesFromQuote = createSelector(
  selectAppointment,
  (appointment) => appointment?.diagnosis?.diagnosisServices
);

export const selectServicesCountFromQuote = createSelector(
  selectServicesFromQuote,
  (services) => {
    return (
      (services?.knownRepairs?.length ?? 0) +
      (services?.customRequests?.length ?? 0) +
      (services?.diagnoses?.length ?? 0)
    );
  }
);

export const selectTotalCostFromQuote = createSelector(
  selectAppointment,
  (appointment) => appointment?.diagnosis.totalPrice
);

export const selectSubTotalFromQuote = createSelector(
  selectAppointment,
  (appointment) => appointment?.diagnosis.subTotal
);

export const selectTotalDiscountFromQuote = createSelector(
  selectAppointment,
  (appointment) => appointment?.repairRequest?.workOrder?.quote?.discounts
);

export const selectTotalDiscountFromSharableQuote = createSelector(
  selectRepairRequestShared,
  (shared) => shared?.repairRequest?.quote?.discounts
);

export const selectAppointmentCarSubscriptionsShared = createSelector(
  selectRepairRequestShared,
  (shared) => shared?.car?.subscriptions
);

export const selectDeliveryFeeFromQuote = createSelector(
  selectAppointment,
  (appointment) => appointment?.diagnosis.deliveryFee
);

export const selectDefaultDeliveryFee = createSelector(
  selectAppointment,
  (appointment) => appointment?.defaultDeliveryFee
);

export const selectAsLowAsPriceFromQuote = createSelector(
  selectAppointment,
  (appointment) => appointment?.diagnosis.asLowAsPrice
);

export const selectAppointmentIdQuote = createSelector(
  selectAppointment,
  (appointment) => appointment?.repairRequest.activeAppointment?.id
);

export const selectTotalJobDurationMinutes = createSelector(
  selectAppointment,
  (appointment) => appointment?.repairRequest.totalJobDurationMinutes
);

export const selectAppointmentCar = createSelector(
  selectAppointment,
  (appointment: AppointmentData) => appointment?.car
);

export const selectAppointmentCarSubscriptions = createSelector(
  selectAppointmentCar,
  (car: Car) => car?.subscriptions
);

export const selectServicesHasPrices = createSelector(
  selectServicesFromQuote,
  (services: any) => {
    let hasPrices = true;

    const customRequests = services?.customRequests ?? [];
    if (customRequests.length) {
      return false;
    }

    const diagnoses = services?.diagnoses ?? [];
    diagnoses.forEach((d: any) => {
      if (!d.inspectionFee) {
        hasPrices = false;
      }
    });

    const knownRepairs = services?.knownRepairs ?? [];
    knownRepairs.forEach((k: any) => {
      k.price.forEach((p: any) => {
        if (isNil(p?.totalCost)) {
          hasPrices = false;
        }
      });
    });

    return hasPrices;
  }
);

/**
 * GETS ALL KNOWN REPAIRS AND POSSIBLE DIAGNOSES REPAIRS
 * @type {OutputSelector<unknown, string, (res1: {symptoms: *, repairs: *}, res2: *) => string>}
 */
export const selectAllRepairsAsString = createSelector(
  selectServicesFromQuote,
  (services: any) => {
    const knownRepairs = services?.knownRepairs ?? [];
    const diagnoses = services?.diagnoses ?? [];
    const customRequests = services?.customRequests ?? [];

    const allRepairs: string[] = [];
    diagnoses.forEach((r: any) => {
      allRepairs.push([...r.symptoms].reverse()[0].name);
    });
    knownRepairs.forEach((v: any) => {
      allRepairs.push(v.name);
    });
    customRequests.forEach((v: any) => {
      allRepairs.push(v.message);
    });

    return allRepairs.join(', ');
  }
);

export const selectAllRepairs = createSelector(
  selectServicesFromQuote,
  (services: any) => {
    const knownRepairs = services?.knownRepairs ?? [];
    const diagnoses = services?.diagnoses ?? [];
    const customRequests = services?.customRequests ?? [];

    const allRepairs: any[] = [];
    diagnoses.forEach((r: any) => {
      allRepairs.push(r);
    });
    knownRepairs.forEach((v: any) => {
      allRepairs.push(v);
    });
    customRequests.forEach((v: any) => {
      allRepairs.push(v);
    });

    return allRepairs;
  }
);

export const selectRequestCar = createSelector(
  getRepairRequest,
  (request) => request.car
);

export const selectRequestFunnel = createSelector(
  getRepairRequest,
  (request) => request.funnel
);

export const selectRequestFunnelPage = (path: string) =>
  createSelector(selectRequestFunnel, (funnel) =>
    funnel?.pages.find((p: FunnelPage) => p.path === path)
  );
export const selectRepairOrderId = createSelector(
  selectAppointment,
  (appointmentData) => appointmentData?.repairRequest?.repairOrder?.id
);

export const selectRepairRefNum = createSelector(
  selectAppointment,
  (appointmentData) => {
    return appointmentData?.repairRequest?.referenceNum;
  }
);

export const selectMaxLeadTime = createSelector(
  selectAppointment,
  (appointmentData) => {
    return appointmentData?.diagnosis?.diagnosisServices?.maxLeadTime;
  }
);

export const select3CsNotes = createSelector(
  selectAppointment,
  (appointmentData) => {
    const notes = appointmentData?.repairRequest?.notes ?? [];

    const result =
      notes
        .filter((note: any) => note.type === 'CONCERN_CAUSE_CORRECTION')
        .map((note: any) => ({
          entityId: note.entityId,
          content: note.content
            .split(/--\w*--/) // any --MARKER-- words with trailing spaces and wraps
            .filter((n: any) => n.length)
            .map((n: any) => n.replace(/^(\s)*/g, '').replace(/(\s)*$/g, '')), // trim white spaces
        })) ?? [];

    return result;
  }
);

export const selectIsFutureAppointment = createSelector(
  selectRepairRequestOrShared,
  (repairRequest) => !!repairRequest?.futureAppointment
);
