import { createAPIAction } from 'store/utils/createAPIAction';
import { PreliminaryCar } from 'entities/PreliminaryCar';
import { Car } from 'entities/Car';
import { KnowRepair, KnowRepairWithPrice } from 'entities/KnowRepair';
import { Diagnose } from 'entities/Diagnose';
import { CustomRequest } from 'entities/CustomRequest';
import { SearchResults } from 'entities/SearchResults';
import { getAnonymousConsumerId } from 'utilities/cookie';
import { Services } from 'store/preliminaryRepair/reducer';

const serviceName = 'repairs';
const { CAR_SELECTOR_FINAL_FIELD } = window.RepairSmith;

export const getRepairsList = createAPIAction(
  `${serviceName}/GET_REPAIRS_LIST`,
  () => ({
    endpoint: '/pricing-service/search/repairs',
  })
);

interface SearchRepairsProps {
  searchTerms?: string;
  from?: number;
  pageSize?: number;
  signal?: any; // todo check search canceling
  fleetId: string | null;
}

export const searchRepairs = createAPIAction<
  [SearchRepairsProps],
  any,
  SearchResults
>(`${serviceName}/SEARCH_REPAIRS`, (data) => ({
  endpoint: '/search-service/search/',
  method: 'POST',
  data,
}));

export const getSymptomTree = createAPIAction(
  `${serviceName}/GET_SYMPTOM_TREE`,
  () => ({
    endpoint: '/symptom-service/v2/symptom-tree',
  })
);

export type GetPricesParams = {
  car?: PreliminaryCar;
  services?: Services;
  ispIds?: number[];
  consumerId?: number;
  quoteId?: string | null;
  fleetId?: string | null;
  shopId?: number | null;
  zip: string;
};

export type GetMotorPricingPayload = {
  car: Partial<Car>;
  diagnoses: Diagnose[];
  knownRepairs: KnowRepair[];
  ispIds: number[];
  requestMetadata: RequestMetadataPayload;
  fleetId?: string | null;
  zip: string;
};

export type RequestMetadataPayload = {
  anonymousConsumerId?: string;
  consumerId?: number;
  referenceNum?: string;
  quoteId?: string | null;
  fleetId?: string | null;
};

type GetPriceResponse = {
  knownRepairs: KnowRepairWithPrice[];
  diagnoses: Diagnose[];
  customRequests: CustomRequest[];
  totalCost: number;
  totalLaborDuration: number;
  shopId: number;
  car: Car;
};

export const getPrices = createAPIAction<
  [GetPricesParams],
  any,
  GetPriceResponse
>(
  `${serviceName}/GET_PRICES`,
  ({ car, services, ispIds, consumerId, quoteId, fleetId, zip }) => {
    const payload: Partial<GetMotorPricingPayload> = {
      car: {
        make: car?.make?.value,
        makeId: car?.make?.id as unknown as number,
        model: car?.model?.value,
        modelId: car?.model?.id as unknown as number,
        year: car?.year?.value,
        trim: (CAR_SELECTOR_FINAL_FIELD === 'trim'
          ? car?.trim?.value
          : null) as unknown as string,
        subModelId: (CAR_SELECTOR_FINAL_FIELD === 'trim'
          ? car?.trim?.id
          : null) as unknown as number,
        engineId:
          CAR_SELECTOR_FINAL_FIELD === 'engine' ? car?.engine?.id : null,
        mileage: car?.mileage?.value,
        vin: car?.vin?.value as unknown as string,
      },
      zip,
    };

    if (ispIds) {
      payload.ispIds = ispIds;
    }

    if (services) {
      payload.diagnoses = services.diagnoses;
      payload.knownRepairs = services.knownRepairs as KnowRepair[];
    }

    if (fleetId) {
      payload.fleetId = fleetId;
    }

    payload.requestMetadata = {
      anonymousConsumerId: getAnonymousConsumerId(),
      consumerId,
      quoteId,
    };

    return {
      endpoint: '/pricing-service/v2/fair/price/quote',
      method: 'POST',
      data: payload,
    };
  }
);

export const getMarketingSources = createAPIAction(
  `${serviceName}/GET_MARKETING_SOURCES`,
  () => ({
    endpoint: '/repair-service/marketing-sources',
  })
);

export const getAllRecommendedRepairs = createAPIAction(
  `${serviceName}/GET_ALL_RECOMMENDED_REPAIRS`,
  () => ({
    endpoint:
      '/repair-service/consumer/cars/recommended-repairs/history?size=100',
  })
);

export const getRecommendedRepairsCar = createAPIAction(
  `${serviceName}/GET_RECOMMENDED_REPAIRS_CAR`,
  (consumerCarId: number | undefined) => ({
    endpoint: `/repair-service/consumer/cars/${consumerCarId}/recommended-repairs/history?size=40`,
  })
);

export const getRecommendedRepairs = createAPIAction(
  `${serviceName}/GET_RECOMMENDED_REPAIRS`,
  (repairIds: number[]) => ({
    endpoint: `/pricing-service/recommended-repairs?repairIds=${repairIds.join(
      ','
    )}`,
  })
);

export const getRepairSkills = createAPIAction(
  `${serviceName}/GET_REPAIR_TAGS`,
  (repairIds: number[]) => ({
    endpoint: `/pricing-service/products/skills?${repairIds
      .map((x) => `productId=${x}`)
      .join('&')}`,
  })
);
