import { SvgIcon } from '@mui/material';

const OilChangeIcon = (props: any) => (
  <SvgIcon {...props} width="56" height="56" viewBox="0 0 56 56">
    <defs>
      <path id="a" d="M0 0h56v56H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <g fill="currentColor" fillRule="nonzero" mask="url(#b)">
        <path d="M50.6 15.926H44.65c-.194 0-.383.02-.568.052a.83.83 0 0 0-.281-.052H37v-.862a.856.856 0 0 0-.85-.863h-2.612c-.413-2.921-2.896-5.174-5.888-5.174s-5.475 2.253-5.888 5.174H19.15c-.47 0-.85.387-.85.863v.862H7.245c-1.403 0-2.545 1.16-2.545 2.587 0 1.427 1.142 2.588 2.545 2.588H18.3v.949c-1.937.4-3.4 2.142-3.4 4.224v16.388c0 2.377 1.905 4.311 4.247 4.311h17.006c2.342 0 4.247-1.934 4.247-4.31v-16.39c0-2.081-1.462-3.824-3.4-4.223v-.95h4.25v7.762c0 1.903 1.525 3.45 3.399 3.45h5.952c1.874 0 3.399-1.547 3.399-3.45v-9.486c0-1.902-1.525-3.45-3.4-3.45zm-22.95-5.174c2.052 0 3.77 1.484 4.164 3.45h-8.328c.395-1.966 2.111-3.45 4.164-3.45zM20 20.238v-4.312h15.3V21.963H20v-1.725zm-12.755-.862a.845.845 0 0 1-.845-.862c0-.476.38-.863.845-.863H18.3v1.725H7.245zM38.7 26.274v16.388c0 1.426-1.143 2.586-2.548 2.586H19.147c-1.404 0-2.547-1.16-2.547-2.586V26.274c0-1.426 1.143-2.586 2.547-2.586h17.005c1.405 0 2.548 1.16 2.548 2.586zm2.55-6.898H37V17.65h4.71a3.464 3.464 0 0 0-.46 1.725zm11.05 9.486c0 .951-.762 1.725-1.7 1.725H44.65c-.937 0-1.699-.774-1.699-1.725v-9.486c0-.951.762-1.725 1.699-1.725h5.952c.937 0 1.699.774 1.699 1.725v9.486z" />
        <path d="M7.95 24.06a.847.847 0 0 0-.7-.372c-.28 0-.54.14-.7.372-.592.871-3.55 5.32-3.55 7.39 0 2.377 1.907 4.312 4.25 4.312 2.344 0 4.25-1.935 4.25-4.312 0-2.07-2.958-6.519-3.55-7.39zm-.7 9.977c-1.406 0-2.55-1.16-2.55-2.587 0-.942 1.327-3.4 2.55-5.34 1.223 1.94 2.55 4.398 2.55 5.34 0 1.426-1.144 2.587-2.55 2.587z" />
      </g>
    </g>
  </SvgIcon>
);

export default OilChangeIcon;
