import { CircularProgress } from '@mui/material';
import LogoStacked from '../../SvgAssets/logo-stacked';

const PageLoader = () => (
  <div className="PageLoader">
    <LogoStacked className="Logo" alt="AutoNation Mobile Service Logo" />

    <div className="PageLoader__progressWrap">
      <CircularProgress disableShrink color="primary" size="50px" />
    </div>
  </div>
);

export default PageLoader;
