import { createAPIAction } from 'store/utils/createAPIAction';
import { MIN_CAR_YEAR } from './constants';

const serviceName = 'autoSelection';

interface CarImageProps {
  year: number;
  make: string;
  model: string;
  trim: string;
}

interface CarByPlateProps {
  state: string;
  plate: string;
  minCheck?: boolean;
}

type GetModelsParams = { year: string | number; makeId: string };

export const getYears = createAPIAction(`${serviceName}/GET_YEARS`, () => ({
  endpoint: `/autoselection-service/v2/?minimumSupportedYear=${MIN_CAR_YEAR}`,
}));

export const getMakes = createAPIAction(
  `${serviceName}/GET_MAKES`,
  (year: number) => ({
    endpoint: `/autoselection-service/v2/${year}`,
  })
);

export const getModels = createAPIAction(
  `${serviceName}/GET_MODELS`,
  ({ year, makeId }: GetModelsParams) => ({
    endpoint: `/autoselection-service/v2/${year}/${makeId}`,
  })
);

export const getCarImage = createAPIAction(
  `${serviceName}/GET_CAR_IMAGE`,
  ({ year, make, model, trim }: CarImageProps) => ({
    endpoint: `/autoselection-service/${year}/${make}/${model}/${trim}`,
  })
);

export const getCarByVin = createAPIAction(
  `${serviceName}/GET_CAR_BY_VIN`,
  ({ vin, minCheck = true }: { vin: string; minCheck: boolean }) => ({
    endpoint: `/autoselection-service/v2/vin/${vin}${
      minCheck ? `?minimumSupportedYear=${MIN_CAR_YEAR}` : ''
    }`,
  })
);

export const getCarByPlate = createAPIAction(
  `${serviceName}/GET_CAR_BY_PLATE`,
  ({ state, plate, minCheck = true }: CarByPlateProps) => ({
    endpoint: `/autoselection-service/plate/${state}/${plate}${
      minCheck ? `?minimumSupportedYear=${MIN_CAR_YEAR}` : ''
    }`,
  })
);
