import { set, get } from 'lodash';
import { APIAction } from './createAPIAction';
import { AnyStoreState } from '../reducers';

const initialPagination = {
  totalElements: 1,
  last: false,
  first: true,
  size: 20,
  nextPage: 0,
};

const createAPIInfiniteScrollHandler = (
  action: APIAction<any, any, any, any>,
  basePath: string,
  pathFromPayload?: string,
  loadAll?: boolean
) => ({
  [action?.typeAPIRequest]: <T extends AnyStoreState>(state: T) =>
    set<T>({ ...state }, [basePath, 'requesting'], true),
  [action?.typeAPISuccess]: <T extends AnyStoreState>(
    state: T,
    { payload }: any
  ) => {
    const defaultData = (get(state, basePath, {}) as any).data || [];
    const newData = pathFromPayload
      ? get(payload, pathFromPayload, [])
      : payload || [];

    return set<T>({ ...state }, basePath, {
      data: loadAll || payload.first ? newData : [...defaultData, ...newData],
      requesting: false,
      errors: [],
      pagination: loadAll
        ? initialPagination
        : {
            totalElements: payload.totalElements,
            first: payload.first,
            last: payload.last,
            size: payload.size,
            nextPage: payload.number + 1,
          },
    });
  },
  [action?.typeAPIFail]: <T extends AnyStoreState>(
    state: T,
    { payload }: any
  ) =>
    set<T>({ ...state }, basePath, {
      data: null,
      requesting: false,
      errors: payload,
    }),
});

export { createAPIInfiniteScrollHandler };
