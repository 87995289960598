import { Diagnose } from 'entities/Diagnose';
import { SymptomTreeChildNode, Result } from 'entities/SymptomTreeChildNode';
import { sortBy, find } from 'lodash';
import isFreeText from '../store/utils/isFreeText';

export const updateDiagnoses = (
  tree?: SymptomTreeChildNode,
  diagnoses?: Diagnose[]
) => {
  if (diagnoses?.length === 0 || !tree) {
    return [];
  }
  const data = tree;

  return diagnoses?.map((diagnosis: Diagnose) => {
    const diagnoseResults = sortBy(diagnosis.traverseNodes, 'nodeId');
    const symptoms = [];
    const repairs: Result[] = [];

    // GET SYMPTOMS AND REPAIRS FROM SELECTED NODEIDS IN THE DECISION TREE
    let node = data;
    let index = 0;
    const level = diagnoseResults.length;

    while (index < level) {
      node = find(node.reports.childNodes, {
        id: diagnoseResults[index].nodeId,
      }) as SymptomTreeChildNode;
      let tSymptomStr = node.name;
      if (isFreeText(node?.responseType)) {
        tSymptomStr += ` - ${diagnoseResults[index].message}`;
      }
      symptoms.push({ symptom: tSymptomStr });
      const isTerminal = (node.nodeType || '') === 'TERMINAL';
      if (isTerminal) {
        node.reports.childNodes.map((v) => repairs.push(v.result as Result));
      }
      index += 1;
    }

    return {
      symptoms,
      repairs,
      traverseNodes: diagnosis.traverseNodes,
      price: diagnosis.price,
      index: diagnosis.index,
    };
  });
};
