import Cookies from 'js-cookie';
import { createAction } from 'store/utils/createAction';

const serviceName = 'global';

export const trackAnalyticsEvent = (
  eventName: string,
  payload: any = null
): Promise<void> => {
  return new Promise((resolve, reject) => {
    const analyticsReady = Boolean(window.analytics);
    if (analyticsReady) {
      window.analytics.track(eventName, payload, resolve);

      // BLOCKING SOFTWARE DOESN'T ALLOW THE ENTIRE SEGMENT CODEBASE TO LOAD, SO THIS WILL BYPASS ANALYTICS
      if (!window.analytics.VERSION) {
        resolve();
      }
    } else {
      reject();
    }
  });
};

export const identifyUserEvent = (user: any, requestData: any) => {
  const analyticsReady = Boolean((global as any).analytics);
  const campaignCookie = Cookies.get('campaign');
  const campaignData = campaignCookie ? JSON.parse(atob(campaignCookie)) : {};
  const userWithCampaign = {
    ...campaignData,
    ...user,
  };
  const userWithCompany = {
    company: 'Sent From Product',
    ...userWithCampaign,
  };

  const userWithCompanyAndRequest = {
    ...userWithCompany,
    ...requestData,
  };

  if (analyticsReady) {
    window.analytics.identify(user.id, userWithCompanyAndRequest, {
      integrations: {
        Salesforce: true,
      },
    });
    return true;
  }

  return false;
};

export const addUserProperty = (consumerId: number, userData: any) => {
  const analyticsReady = Boolean((global as any).analytics);

  if (analyticsReady) {
    window.analytics.identify(consumerId, userData);
  }
};

export const setSnackbarMessage = createAction(
  `${serviceName}/SET_SNACKBAR_MESSAGE`
);

export const clearSnackbarMessage = createAction(
  `${serviceName}/CLEAR_SNACKBAR_MESSAGE`
);

export const setSharedAnalytics = createAction(
  `${serviceName}/SET_SHARED_ANALYTICS`
);
